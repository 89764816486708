import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import jwt_decode from 'jwt-decode';
import { toast } from 'react-toastify';

const APIURL = process.env.REACT_APP_API_URL
const presistedState = localStorage.getItem('persist:root') ? JSON.parse(localStorage.getItem('persist:root')!) : null;
var authTokens: { access?: string, refresh?: string } | null = localStorage.getItem('authTokens') ? JSON.parse(localStorage.getItem('authTokens')!) : null;
var token = authTokens && 'access' in authTokens && typeof authTokens.access == 'string' ? jwt_decode(authTokens.access) as DecodedToken : null

var currentTimeStamp: number = Math.floor(Date.now() / 1000);
if (presistedState && 'user' in presistedState) {
    const userPresistedState = JSON.parse(presistedState.user)
    if ('cacheCreatedAt' in userPresistedState && typeof userPresistedState.cacheCreatedAt === 'number') {
        if ((userPresistedState.cacheCreatedAt + (60 * 60 * 24)) <= currentTimeStamp) {
            localStorage.removeItem('persist:root')
        }
    } else {
        localStorage.removeItem('persist:root')
    }
}
export const mdScreenSize: number = 768

export interface DecodedToken {
    hid: string;
    username: string;
    is_member: boolean;
    is_guest: boolean;
    is_affiliate: boolean;
    accepted_terms: boolean;
    email: string;
    exp: number;
    iat: number;
}
export interface CaptchaComponent {
    getValue: () => string | null;
}

interface userLoadingType {
    userLogin: boolean;
    userForgotPassword: boolean;
    userSignUp: boolean;
    userResetPassword: boolean;
    userActivation: boolean;
    userContact: boolean;
    userAcceptTerms: boolean;
    userLogout: boolean;
    userUpdateToken: boolean;
    userCreateGuest: boolean;
    userAffiliateAccountCreation: boolean,
    userGetAffiliateTally: boolean,
}
export interface UserState {
    cacheCreatedAt: number | null;
    isMdScreen: boolean | null;
    theme: string;
    showSideBar?: boolean;
    navTab?: string;
    activeComponent: string | null;
    activeSubComponent: string | null;
    loading: userLoadingType;
    hid?: string | null;
    userName?: string | null;
    is_member?: boolean;
    is_guest?: boolean;
    is_fresh_guest?: boolean;
    is_affiliate?: boolean;
    affiliate_tally?: string | null;
    affiliate_promo_code?: string | null;
    stripeClientSecret?: string | null;
    accepted_terms?: boolean;
    email?: string | null;
    accessToken?: string | null;
    refreshToken?: string | null;
    uidb64: string | null;
    token: string | null;
    rtype: string | null;
    routeTo: string | null;
}
const initialState: UserState = {
    cacheCreatedAt: currentTimeStamp,
    isMdScreen: null,
    theme: 'dark',
    showSideBar: true,
    navTab: 'FindCourses',
    activeComponent: null,
    activeSubComponent: null,
    loading: {
        userLogin: false,
        userForgotPassword: false,
        userSignUp: false,
        userResetPassword: false,
        userActivation: false,
        userContact: false,
        userAcceptTerms: false,
        userLogout: false,
        userUpdateToken: false,
        userCreateGuest: false,
        userAffiliateAccountCreation: false,
        userGetAffiliateTally: false,
    },
    hid: authTokens && token ? token!.hid : null,
    userName: authTokens && token ? token!.username : null,
    is_member: authTokens && token ? token!.is_member : false,
    is_guest: authTokens && token ? token!.is_guest : false,
    is_fresh_guest: false,
    is_affiliate: authTokens && token ? token!.is_affiliate : false,
    affiliate_tally: null,
    affiliate_promo_code: null,
    stripeClientSecret: null,
    accepted_terms: authTokens && token ? token!.accepted_terms : false,
    email: authTokens && token ? token!.email : null,
    accessToken: authTokens?.access,
    refreshToken: authTokens?.refresh,
    uidb64: null,
    token: null,
    rtype: null,
    routeTo: null,
};

const userSlice = createSlice({
    name: 'course',
    initialState,
    reducers: {
        userSetURLParams: (state, action) => {
            let allowedParams: string[][] = [
                ['uidb64', 'uidb64'],
                ['token', 'token'],
                ['rtype', 'rtype']
            ]
            // Initialize an empty object to hold the state updates.
            const stateUpdates: { [key: string]: string | null } = {};
            // Iterate over the allowed parameters.
            const urlParams = new URLSearchParams(action.payload.search);
            allowedParams.forEach(paramTuple => {
                // Check if the current parameter is present in the URL search parameters.
                const value = urlParams.get(paramTuple[1]);
                if (value !== null || value !== undefined || value !== '') {
                    // If present, update the state for this parameter.
                    stateUpdates[paramTuple[0]] = value;
                } else {
                    stateUpdates[paramTuple[0]] = null;
                }
            });
            // Update the state object with the allowed parameters found.
            Object.assign(state, stateUpdates);
        },
        setIsMdScreen: (state, action) => {
            state.isMdScreen = action.payload
        },
        resetRoute: (state) => {
            state.routeTo = null
        },
        refreshUserState: (state) => {
            state.loading = initialState.loading;
        },
        setShowSideBar: (state, action) => {
            state.showSideBar = action.payload
        },
        setnavTab: (state, action) => {
            state.navTab = action.payload
        },
        setActiveComponent: (state, action) => {
            state.activeComponent = action.payload
        },
        setActiveSubComponent: (state, action) => {
            state.activeSubComponent = action.payload
        },
        userLogout: (state) => {
            state.hid = null
            state.userName = null
            state.is_member = false
            state.is_guest = false
            state.is_affiliate = false
            state.accepted_terms = false
            state.email = null
            state.accessToken = null
            state.refreshToken = null
            state.activeComponent = 'SubComponentExplore'
            state.activeSubComponent = ''
            localStorage.removeItem('authTokens')
        },
        setIsFreshGuest: (state) => {
            state.is_fresh_guest = false
        },
        toggleTheme: (state) => {
            if (localStorage.theme) {
                state.theme = 'dark'
                document.documentElement.classList.add('dark')
                document.documentElement.classList.remove('light')
                document.documentElement.style.backgroundColor = '#1e293b';
                document.querySelector('meta[name="theme-color"]')?.setAttribute('content', '#1e293b'); // Dark theme color
                localStorage.removeItem('theme')
            } else {
                state.theme = 'light'
                document.documentElement.classList.add('light')
                document.documentElement.classList.remove('dark')
                document.documentElement.style.backgroundColor = '#f1f5f9';
                document.querySelector('meta[name="theme-color"]')?.setAttribute('content', '#f1f5f9'); // Light theme color
                localStorage.setItem('theme', 'light')
            }
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(userLogin.pending, (state) => {
                state.loading.userLogin = true;
            })
            .addCase(userLogin.fulfilled, (state, action) => {
                state.loading.userLogin = false;
                if (action.payload.status === 200) {
                    let tokenInformation: { hid: string, username: string, email: string, is_member: boolean, is_guest: boolean, is_affiliate: boolean, accepted_terms: boolean } = jwt_decode(action.payload.data.access)
                    state.hid = tokenInformation.hid
                    state.userName = tokenInformation.username
                    state.is_member = tokenInformation.is_member
                    state.is_guest = tokenInformation.is_guest
                    state.is_affiliate = tokenInformation.is_affiliate
                    state.accepted_terms = tokenInformation.accepted_terms
                    state.email = tokenInformation.email
                    state.accessToken = String(action.payload.data.access)
                    state.refreshToken = String(action.payload.data.refresh)
                    state.activeComponent = 'SubComponentExplore'
                    state.activeSubComponent = ''
                    state.showSideBar = true
                    localStorage.setItem('authTokens', JSON.stringify(action.payload.data))
                    toast.success("You're logged in!");
                } else {
                    toast.error("Something went wrong, please try again.");
                }
            })
            .addCase(userForgotPassword.pending, (state) => {
                state.loading.userForgotPassword = true;
            })
            .addCase(userForgotPassword.fulfilled, (state, action) => {
                state.loading.userForgotPassword = false; if (action.payload.status === 200) {
                    toast.info(action.payload.data['message']);
                } else {
                    toast.error(action.payload.data['message']);
                }
                state.activeComponent = 'SubComponentUserRegistration'
                state.activeSubComponent = 'PrivateComponentLogin'
            })
            .addCase(userSignUp.pending, (state) => {
                state.loading.userSignUp = true;
            })
            .addCase(userSignUp.fulfilled, (state, action) => {
                state.loading.userSignUp = false;
                if (action.payload.status === 200) {
                    state.activeSubComponent = 'SubComponentUserRegistration'
                    state.activeSubComponent = 'PrivateComponentLogin'
                    toast.success(action.payload.data['message']);
                } else {
                    toast.error(action.payload.data['message']);
                }
            })
            .addCase(userResetPassword.pending, (state) => {
                state.loading.userResetPassword = true;
            })
            .addCase(userResetPassword.fulfilled, (state, action) => {
                state.loading.userResetPassword = false;
                if (action.payload.status === 200) {
                    state.activeComponent = 'SubComponentUserRegistration'
                    state.activeSubComponent = 'PrivateComponentLogin'
                    state.showSideBar = false
                    state.uidb64 = null
                    state.token = null
                    state.rtype = null
                    state.routeTo = '/chat'
                    toast.info(action.payload.data['message']);
                } else {
                    toast.error(action.payload.data['message']);
                }
            })
            .addCase(userActivation.pending, (state) => {
                state.loading.userActivation = true;
            })
            .addCase(userActivation.fulfilled, (state, action) => {
                state.loading.userActivation = false;
                if (action.payload.status === 200) {
                    state.uidb64 = null
                    state.token = null
                    state.rtype = null
                    toast.info(action.payload.data['message']);
                } else {
                    toast.error(action.payload.data['message']);
                }
                state.routeTo = '/chat'
                state.activeComponent = 'SubComponentUserRegistration'
                state.activeSubComponent = 'PrivateComponentLogin'
                state.showSideBar = false
            })
            .addCase(userContact.pending, (state) => {
                state.loading.userContact = true;
            })
            .addCase(userContact.fulfilled, (state, action) => {
                state.loading.userContact = false;
                if (action.payload.status === 200) {
                    console.info(action.payload.data['message']);
                    toast.success('We got you message, check your email for a confirmation.');
                } else {
                    toast.error(action.payload.data['message']);
                }
            })
            .addCase(userAcceptTerms.pending, (state) => {
                state.loading.userAcceptTerms = true;
            })
            .addCase(userAcceptTerms.fulfilled, (state, action) => {
                state.loading.userAcceptTerms = false;
                if (action.payload.status === 200) {
                    state.accepted_terms = true
                    toast.success('Welcome To Coursely!');
                } else {
                    console.info(action.payload.data['message']);
                }
            })
            .addCase(userUpdateToken.pending, (state) => {
                state.loading.userUpdateToken = true;
            })
            .addCase(userUpdateToken.fulfilled, (state, action) => {
                state.loading.userUpdateToken = false;
                if (action.payload.status === 200) {
                    let tokenInformation: { hid: string, username: string, email: string, is_member: boolean, is_guest: boolean, is_affiliate: boolean, accepted_terms: boolean } = jwt_decode(action.payload.data.access)
                    state.hid = tokenInformation.hid
                    state.userName = tokenInformation.username
                    state.is_member = tokenInformation.is_member
                    state.is_guest = tokenInformation.is_guest
                    state.is_affiliate = tokenInformation.is_affiliate
                    state.accepted_terms = tokenInformation.accepted_terms
                    state.email = tokenInformation.email
                    state.accessToken = String(action.payload.data.access)
                    state.showSideBar = true
                    // Get the existing authTokens from localStorage
                    let authTokens = localStorage.getItem('authTokens') ? JSON.parse(localStorage.getItem('authTokens')!) : null;
                    // Check if authTokens exists
                    if (authTokens) {
                        // Update the access token
                        authTokens.access = action.payload.data;

                        // Convert the updated authTokens object back to a string
                        const updatedAuthTokens = JSON.stringify(authTokens);

                        // Store the updated authTokens back in localStorage
                        localStorage.setItem('authTokens', updatedAuthTokens);
                        console.info('Access Token refreshed.');
                    } else {
                        // If authTokens does not exist, create a new object and store it
                        localStorage.removeItem('authTokens')
                        console.error('Access Token refresh failed. All tokens have been erased.');
                    }
                } else {
                    console.error('Access Token refresh failed.');
                }
            })
            .addCase(userCreateGuest.pending, (state) => {
                state.loading.userCreateGuest = true;
            })
            .addCase(userCreateGuest.fulfilled, (state, action) => {
                state.loading.userCreateGuest = false;
                if (action.payload.status === 200) {
                    let tokenInformation: { hid: string, username: string, email: string, is_member: boolean, is_guest: boolean, accepted_terms: boolean } = jwt_decode(action.payload.data.data.access)
                    state.hid = tokenInformation.hid
                    state.userName = tokenInformation.username
                    state.is_member = tokenInformation.is_member
                    state.is_guest = tokenInformation.is_guest
                    state.is_fresh_guest = true
                    state.accepted_terms = tokenInformation.accepted_terms
                    state.email = tokenInformation.email
                    state.accessToken = String(action.payload.data.data.access)
                    state.showSideBar = true
                    localStorage.setItem('authTokens', JSON.stringify(action.payload.data.data))
                    toast.success('Welcome to coursely!');
                } else {
                    toast.error(action.payload.data.message);
                }
            })
            .addCase(userAffiliateAccountCreation.pending, (state) => {
                state.loading.userAffiliateAccountCreation = true;
            })
            .addCase(userAffiliateAccountCreation.fulfilled, (state, action) => {
                state.loading.userAffiliateAccountCreation = false;
                if (action.payload.status === 200) {
                    window.location.href = action.payload.data['data']['url'];
                    console.info(action.payload.data['message']);
                } else {
                    console.error(action.payload.data['message']);
                }
            })
            .addCase(userGetAffiliateTally.pending, (state) => {
                state.loading.userGetAffiliateTally = true;
            })
            .addCase(userGetAffiliateTally.fulfilled, (state, action) => {
                state.loading.userGetAffiliateTally = false;
                if (action.payload.status === 200) {
                    state.affiliate_promo_code = action.payload.data['data']['affiliate_promo_code']
                    state.affiliate_tally = action.payload.data['data']['affiliate_tally']
                    console.info(action.payload.data['message']);
                } else {
                    console.error(action.payload.data['message']);
                }
            })
    }
})

export const userLogin = createAsyncThunk(
    'user/userLogin',
    async (input: { userName: string, password: string }) => {
        await new Promise(resolve => setTimeout(resolve, 300));
        let response = await fetch(
            `${APIURL}/login.json`,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    'username': input.userName,
                    'password': input.password,
                })
            }
        );
        let data = await response.json();
        return { data: data, status: response.status };
    }
)
export const userForgotPassword = createAsyncThunk(
    'user/forgotPassword',
    async (input: { userName: string, recaptchaToken: string | null }) => {
        await new Promise(resolve => setTimeout(resolve, 300));
        let response = await fetch(
            `${APIURL}/password_reset_trigger.json`,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    'username': input.userName,
                    'g-recaptcha-response': input.recaptchaToken,
                })
            }
        )
        let data = await response.json();
        return { data: data, status: response.status };
    }
)
export const userSignUp = createAsyncThunk(
    'user/userSignUp',
    async (input: {
        guest_hid: string | null,
        firstName: string,
        lastName: string,
        userName: string,
        email: string,
        password: string,
        confirmPassword: string,
        recaptchaToken: string | null
    }) => {
        await new Promise(resolve => setTimeout(resolve, 300));
        let response = await fetch(
            `${APIURL}/signup.json`,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    'guest_hid': input.guest_hid,
                    'first_name': input.firstName,
                    'last_name': input.lastName,
                    'username': input.userName,
                    'email': input.email,
                    'password': input.password,
                    'confirmpassword': input.confirmPassword,
                    'g-recaptcha-response': input.recaptchaToken,
                })
            }
        )
        let data = await response.json();
        return { data: data, status: response.status };
    }
)
export const userResetPassword = createAsyncThunk(
    'user/userResetPassword',
    async (input: {
        uidb64: string,
        token: string,
        password: string,
        confirmpassword: string,
    }) => {
        await new Promise(resolve => setTimeout(resolve, 300));
        let response = await fetch(
            `${APIURL}/password_reset_return.json`,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    'uidb64': input.uidb64,
                    'token': input.token,
                    'password': input.password,
                    'confirmpassword': input.confirmpassword,
                })
            }
        )
        let data = await response.json();
        return { data: data, status: response.status };
    }
)
export const userActivation = createAsyncThunk(
    'user/userActivation',
    async (input: {
        uidb64: string,
        token: string,
    }) => {
        await new Promise(resolve => setTimeout(resolve, 300));
        let response = await fetch(
            `${APIURL}/activate.json`,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    'uidb64': input.uidb64,
                    'token': input.token,
                })
            }
        )
        let data = await response.json();
        return { data: data, status: response.status };
    }
)
export const userContact = createAsyncThunk(
    'user/userContact',
    async (input: {
        messageSubject: string,
        message: string,
        accessToken: string
    }) => {
        await new Promise(resolve => setTimeout(resolve, 300));
        let response = await fetch(
            `${APIURL}/contact.json`,
            {

                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + String(input.accessToken),
                },
                body: JSON.stringify({
                    'messageSubject': input.messageSubject,
                    'message': input.message,
                })
            }
        )
        let data = await response.json();
        return { data: data, status: response.status };
    }
)
export const userAcceptTerms = createAsyncThunk(
    'user/userAcceptTerms',
    async (input: {
        accessToken: string
    }) => {
        await new Promise(resolve => setTimeout(resolve, 300));
        let response = await fetch(
            `${APIURL}/accept_terms.json`,
            {

                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + String(input.accessToken),
                },
            }
        )
        let data = await response.json();
        return { data: data, status: response.status };
    }
)
export const userUpdateToken = createAsyncThunk(
    'user/userUpdateToken',
    async (input: {
        refreshToken: string,
    }) => {
        let response = await fetch(
            `${APIURL}/re_login.json`,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    'refresh': input.refreshToken,
                })
            })
        let data = await response.json();
        return { data: data, status: response.status };
    }
)
export const userCreateGuest = createAsyncThunk(
    'user/userCreateGuest',
    async () => {
        let response = await fetch(
            `${APIURL}/create_guest.json`,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
            })
        let data = await response.json();
        return { data: data, status: response.status };
    }
)
export const userAffiliateAccountCreation = createAsyncThunk(
    'user/userAffiliateAccountCreation',
    async (input: { accessToken: string }) => {
        await new Promise(resolve => setTimeout(resolve, 500));
        let url = `${APIURL}/affiliate_account_link.json`
        var custom_headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + String(input.accessToken),
        };
        let response = await fetch(
            url,
            {
                method: 'GET',
                headers: custom_headers
            }
        )
        let data = await response.json();
        return { data: data, status: response.status };
    }
);

export const userGetAffiliateTally = createAsyncThunk(
    'user/userGetAffiliateTally',
    async (input: { accessToken: string }) => {
        await new Promise(resolve => setTimeout(resolve, 500));
        let url = `${APIURL}/affiliate_tally.json`
        var custom_headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + String(input.accessToken),
        };
        let response = await fetch(
            url,
            {
                method: 'GET',
                headers: custom_headers
            }
        )
        let data = await response.json();
        return { data: data, status: response.status };
    }
);

export const {
    userSetURLParams,
    setIsMdScreen,
    userLogout,
    setIsFreshGuest,
    toggleTheme,
    setShowSideBar,
    setnavTab,
    setActiveComponent,
    setActiveSubComponent,
    resetRoute,
    refreshUserState
} = userSlice.actions;

export default userSlice.reducer;
