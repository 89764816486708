import React, { useState, useEffect, useMemo, useRef } from 'react';
import { AppDispatch, RootState } from '../state/store';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom'



import { Modal, Card, Progress } from "flowbite-react";

import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import { FontAwesomeIcon, } from '@fortawesome/react-fontawesome'
import {
    faHourglassHalf,
    faBook,
    faCheck,
    faEllipsisVertical,
    faSquarePlus,
    faChartLine,
    faGraduationCap,
    faComments,
    faPersonRunning,
    faArrowRightToBracket,
    faStreetView,
    faCircleCheck,
    faCircleExclamation,
    faSquareXmark,
    faSquareCheck,
    faArrowsRotate,
    faBan,
    faAnglesLeft,
    faAnglesRight,
    faSignature,
    faBoltLightning,
    faGripLinesVertical,
    faClipboardQuestion,
    faPenToSquare,
    faSpinner,
    faCircle,
    faCrown,
    faSpellCheck,
} from '@fortawesome/free-solid-svg-icons'

import Markdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import rehypeKatex from 'rehype-katex'
import remarkMath from 'remark-math'
import 'katex/dist/katex.min.css'


import {
    setActiveComponent,
    setActiveSubComponent,
    setShowSideBar,
    setnavTab,
    userCreateGuest,
    userLogout
} from '../state/user/userSlice'

import {
    courseGetAIRequestTicket,
    myjsonrepair,
    setRequestAIResponse,
    setChatThread,
    setFlashCardUserInputs,
    addUserItem,
    runStream,
    updateChatItem,
    courseGetAIAssessmentRequestTicket,
    setRequestAIAssessmentResponse,
    runAssessmentStream,
    courseSaveUserInput,
    setAssessmentUserInputs,
    CourseState,
    FlashCardsType,
    QuizType,
    VocabularyType,
    ClozeCardsType,
    courseSaveCourseSubscription,
    courseGetAssessment,
    addGeneralTutorUserItem,
    setGeneralTutorLessonChatThread,
    updateGeneralTutorChatItem,
    runGeneralTutorStream,
    generalTutorGetAssessment,
    runGeneralTutorAssessmentStream,
    resetAssessment,
    courseGetPortalSession,
    courseGetCheckoutSession,
    unsetCourseState,
    courseToggleSaveCourseSubscription,
    generalTutorDeleteCourse,
} from '../state/course/courseSlice'
import { TwSprkles } from './_icons';
import { SubComponentPerformanceInterface } from './_subComponents';
import { toast } from 'react-toastify';


declare global {
    interface Window {
        StripeBuyButton: any;
    }
}
export function TwProgressBar(props: { bgcolor: string, progress: number }) {
    return (
        <div
            className="relative w-[60%] h-[10px] m-auto bg-slate-200 rounded-full mt-2 overflow-hidden text-xs"
        >
            <div
                className={`h-full transition-all duration-300 ease-in-out`}
                style={{ width: `${props.progress}%`, backgroundColor: props.bgcolor }}
            >
            </div>
        </div>
    );
}
export function TwChatInput() {
    const course = useSelector((state: RootState) => state.course)
    const dispatch = useDispatch<AppDispatch>();
    //
    const [submittedInput, setSubmittedInput] = useState(false)
    const actionInitiatedRef = useRef(false);
    const [textInput, setTextInput] = useState<string | null>('')
    //
    const handleKeyPress = (event: { key: string, shiftKey: boolean }) => {
        if (event.key === 'Enter' && event.shiftKey) {
            // Call your function here
        } else if (event.key === 'Enter') {
            dispatch(addUserItem({ thread_index: course.activeThread, user_input: textInput }))
            setSubmittedInput(true)
            setTextInput('')
            const editableDiv = document.getElementById('editableDiv');
            if (editableDiv) {
                editableDiv.innerHTML = '';
            }
        }
    };
    useEffect(() => {
        if (submittedInput && !actionInitiatedRef.current) {
            setSubmittedInput(false)
            actionInitiatedRef.current = true;
            if ('chat_threads' in course.courseLesson && course.activeThread !== null) {
                let pair_index = Number(course?.courseLesson?.chat_threads[course.activeThread][1].length) - 1
                dispatch(setRequestAIResponse({
                    activation: true,
                    courseId: course.courseId,
                    lessonNumber: course.lessonNumber,
                    threadIndex: Number(course.activeThread),
                    pairIndex: Number(pair_index),
                }));
            }
        } else if (!submittedInput) {
            actionInitiatedRef.current = false; // Reset ref when submittedInput is false again
        }
    }, [dispatch, course.activeThread, course.courseId, course.courseLesson, course.lessonNumber, submittedInput])
    //
    return (
        <>
            <div className="flex items-center px-1 py-2 rounded-2xl border-[1px] border-slate-800 dark:border-slate-200 mb-1 m-auto w-full">
                {!course.loading.courseGetAIRequestTicket && !course.loading.streaming ? (
                    <button
                        type="submit"
                        className="mx-2 inline-flex justify-center p-2 text-blue-600 rounded-full cursor-pointer hover:bg-blue-100 dark:text-blue-500 dark:hover:bg-gray-600"
                        onClick={() => {
                            dispatch(addUserItem({ thread_index: course.activeThread, user_input: textInput }));
                            setSubmittedInput(true);
                            setTextInput('');
                            const editableDiv = document.getElementById('editableDiv')
                            if (editableDiv) {
                                editableDiv.innerHTML = '';
                            }
                        }}
                    >
                        <svg className="w-5 h-5 rotate-90 rtl:-rotate-90" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 18 20">
                            <path d="m17.914 18.594-8-18a1 1 0 0 0-1.828 0l-8 18a1 1 0 0 0 1.157 1.376L8 18.281V9a1 1 0 0 1 2 0v9.281l6.758 1.689a1 1 0 0 0 1.156-1.376Z" />
                        </svg>
                        <span className="sr-only">Send message</span>
                    </button>
                ) : (
                    <button
                        type="submit"
                        className="mx-2 inline-flex justify-center p-2 text-blue-600 rounded-full cursor-pointer hover:bg-blue-100 dark:text-blue-500 dark:hover:bg-gray-600"
                        disabled
                    >
                        <FontAwesomeIcon icon={faBan} className='w-4 h-4' />
                    </button>
                )}
                {!course.loading.courseGetAIRequestTicket && !course.loading.streaming ? (
                    <div
                        id="editableDiv"
                        className="block mr-2 w-full resize-none text-base text-gray-900 rounded-lg dark:placeholder-gray-400 dark:text-white text-start overflow-scroll focus:ring-0 focus-visible:ring-0 outline-none whitespace-pre-line bg-transparent p-2 border-l rounded-none"
                        style={{ maxHeight: '150px' }}
                        contentEditable="true"
                        suppressContentEditableWarning={true}
                        onInput={(e) => { setTextInput(e.currentTarget.textContent); }}
                        onKeyDown={handleKeyPress}
                        data-placeholder='Your message...'
                    ></div>
                ) : (
                    <div
                        id="editableDiv"
                        className="block mr-2 w-full resize-none text-base text-start  text-gray-500  overflow-scroll focus:ring-0 focus-visible:ring-0 outline-none whitespace-pre-line bg-transparent p-2 border-l rounded-none"
                        style={{ maxHeight: '150px' }}
                        data-placeholder='Your message...'
                    ></div>
                )}
            </div >
        </>

    )
}
export function TwGeneralTutorChatInput() {
    const course = useSelector((state: RootState) => state.course)
    const dispatch = useDispatch<AppDispatch>();
    //
    const [submittedInput, setSubmittedInput] = useState(false)
    const actionInitiatedRef = useRef(false);
    const [textInput, setTextInput] = useState<string | null>('')
    //
    const handleKeyPress = (event: { key: string, shiftKey: boolean }) => {
        if (event.key === 'Enter' && event.shiftKey) {
            // Call your function here
        } else if (event.key === 'Enter') {
            dispatch(addGeneralTutorUserItem({ thread_index: course.activeGeneralTutorThread, user_input: textInput }))
            setSubmittedInput(true)
            setTextInput('')
            const editableDiv = document.getElementById('editableDiv');
            if (editableDiv) {
                editableDiv.innerHTML = '';
            }
        }
    };
    useEffect(() => {
        if (submittedInput && !actionInitiatedRef.current) {
            setSubmittedInput(false)
            actionInitiatedRef.current = true;
            if ('chat_threads' in course.generalTutorLesson && course.activeGeneralTutorThread !== null) {
                let pair_index = Number(course?.generalTutorLesson?.chat_threads[course.activeGeneralTutorThread][1].length) - 1
                dispatch(setRequestAIResponse({
                    activation: true,
                    is_head: false,
                    threadIndex: Number(course.activeGeneralTutorThread),
                    pairIndex: Number(pair_index),
                    generalTutorLessonHid: course.generalTutorLesson.hid,
                }));
            }
        } else if (!submittedInput) {
            actionInitiatedRef.current = false; // Reset ref when submittedInput is false again
        }
    }, [dispatch, course.activeGeneralTutorThread, course.generalTutorLesson, submittedInput])
    //
    return (
        <>
            <div className="flex items-center px-1 py-2 rounded-2xl border-[1px] border-slate-800 dark:border-slate-200 mb-1 m-auto w-full">
                {!course.loading.courseGetAIRequestTicket &&
                    !course.loading.streaming &&
                    'lessonpoints' in course.generalTutorLesson &&
                    course.generalTutorLesson.lessonpoints &&
                    course.generalTutorLesson.lessonpoints.length > 0 ? (
                    <button
                        type="submit"
                        className="mx-2 inline-flex justify-center p-2 text-blue-600 rounded-full cursor-pointer hover:bg-blue-100 dark:text-blue-500 dark:hover:bg-gray-600"
                        onClick={() => {
                            dispatch(addGeneralTutorUserItem({ thread_index: course.activeGeneralTutorThread, user_input: textInput }));
                            setSubmittedInput(true);
                            setTextInput('');
                            const editableDiv = document.getElementById('editableDiv')
                            if (editableDiv) {
                                editableDiv.innerHTML = '';
                            }
                        }}
                    >
                        <svg className="w-5 h-5 rotate-90 rtl:-rotate-90" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 18 20">
                            <path d="m17.914 18.594-8-18a1 1 0 0 0-1.828 0l-8 18a1 1 0 0 0 1.157 1.376L8 18.281V9a1 1 0 0 1 2 0v9.281l6.758 1.689a1 1 0 0 0 1.156-1.376Z" />
                        </svg>
                        <span className="sr-only">Send message</span>
                    </button>
                ) : (
                    <button
                        type="submit"
                        className="mx-2 inline-flex justify-center p-2 text-blue-600 rounded-full cursor-pointer hover:bg-blue-100 dark:text-blue-500 dark:hover:bg-gray-600"
                        disabled
                    >
                        <FontAwesomeIcon icon={faBan} className='w-4 h-4' />
                    </button>
                )}
                {!course.loading.courseGetAIRequestTicket &&
                    !course.loading.streaming &&
                    'lessonpoints' in course.generalTutorLesson &&
                    course.generalTutorLesson.lessonpoints &&
                    course.generalTutorLesson.lessonpoints.length > 0 ? (
                    <div
                        id="editableDiv"
                        className="block mr-2 w-full resize-none text-base text-gray-900 rounded-lg dark:placeholder-gray-400 dark:text-white text-start overflow-scroll focus:ring-0 focus-visible:ring-0 outline-none whitespace-pre-line bg-transparent p-2 border-l rounded-none"
                        style={{ maxHeight: '150px' }}
                        contentEditable="true"
                        suppressContentEditableWarning={true}
                        onInput={(e) => { setTextInput(e.currentTarget.textContent); }}
                        onKeyDown={handleKeyPress}
                        data-placeholder='Your message...'
                    ></div>
                ) : (
                    <div
                        id="editableDiv"
                        className="block mr-2 w-full resize-none text-base text-start  text-gray-500  overflow-scroll focus:ring-0 focus-visible:ring-0 outline-none whitespace-pre-line bg-transparent p-2 border-l rounded-none"
                        style={{ maxHeight: '150px' }}
                        data-placeholder='Your message...'
                    ></div>
                )}
            </div >
        </>

    )
}
export function TwButton(props: {
    children?: React.ReactNode;
    ukey?: string,
    id?: any;
    onClick?: React.MouseEventHandler<HTMLButtonElement>;
    href?: any;
    disabled?: any;
    className?: string;
}) {
    return (
        <button id={props.id} onClick={props.onClick} ref={props.href} disabled={props.disabled}
            className={`
			font-medium px-2 
			py-2 rounded-lg 
			mr-1

			hover:bg-slate-900 
			hover:text-slate-50
			border
			border-black

			dark:bg-slate-800 
			dark:text-slate-50
			dark:hover:text-slate-950
			dark:hover:bg-slate-50
			dark:border-white
            justify-center
            text-center
			${props.className}
			`}>
            {props.children}
        </button>
    )
}
export function TwLoading(props: { className?: string }) {
    return (
        <div className="flex gap-4 flex-wrap justify-center">
            <img className={`w-4 h-4 animate-spin-fast ${props.className}`} src="https://www.svgrepo.com/show/199956/loading-loader.svg" alt="Loading icon" />
        </div>
    )
}
export function TwPulsing(props: { className?: string }) {
    return (
        <div className={`relative inline-flex ${props.className}`}>
            <div className="w-3 h-3 bg-blue-500 rounded-full"></div>
            <div className="w-3 h-3 bg-blue-500 rounded-full absolute top-0 left-0 animate-ping"></div>
            <div className="w-3 h-3 bg-blue-500 rounded-full absolute top-0 left-0 animate-pulse"></div>
        </div>
    )
}
export function TwAnchor(props: {
    href?: string;
    className?: string;
    children?: React.ReactNode;
}) {
    return (
        <Link to={`${props.href}`} className={`
			text-blue-500
			underline
			hover:text-blue-400
			hover:cursor-pointer
			${props.className}
			`}>
            {props.children}
        </Link>
    )
}
export function TwHome() {
    const course = useSelector((state: RootState) => state.course)
    const user = useSelector((state: RootState) => state.user)
    const dispatch = useDispatch<AppDispatch>();
    let navigate = useNavigate();
    //
    const iconJustify = 'flex justify-center items-center h-full p-2 rounded-md'
    //
    const [subscriptionModal, setSubscriptionModal] = useState(false) //
    const [profileModal, setProfileModal] = useState(false) //


    return (
        <>
            <div className='flex flex-col md:flex-row justify-between justify-end w-[95%] m-auto'>
                <div className='mr-4'>
                    <div className='text-xl opacity-50 text-center'>
                        Welcome back,
                    </div>
                    <div className='flex flex-row align-middle text-xl ml-4 justify-center mt-2 md:mt-0 mb-8 md:mb-0'>
                        {!user.hid ? (
                            <>
                                <TwButton
                                    onClick={() => {
                                        dispatch(setActiveComponent('SubComponentUserRegistration'));
                                        dispatch(setActiveSubComponent('PrivateComponentLogin'));
                                    }}
                                >
                                    <div className="flex flex-row m-0 ml-1 text-[14px] w-full justify-between">
                                        <div
                                            className={`p-1 flex flex-row overflow-scroll font-bold text-[11px] truncate items-center`}
                                        >
                                            <FontAwesomeIcon icon={faArrowRightToBracket} className='mr-2' size='2xl' />
                                            {user.is_guest ? (
                                                'Finish signup'
                                            ) : (
                                                'Login/Signup'
                                            )}
                                        </div>
                                    </div>
                                </TwButton>
                            </>
                        ) : (
                            <div className='flex flex-col'>
                                <div className='flex flex-row hover:underline hover:cursor-pointer mb-3 text-[14px]'
                                    onClick={() => {
                                        if (user.is_guest) {
                                            dispatch(setActiveComponent('SubComponentUserRegistration'))
                                            dispatch(setActiveSubComponent('PrivateComponentSignUp'))
                                        } else {
                                            setProfileModal(true)
                                        }
                                    }}
                                >
                                    <FontAwesomeIcon icon={faStreetView} className='mr-2 w-6 h-6' />
                                    {'hid' in user && user.hid ? user.userName : 'Guest'}
                                </div>
                                <div className='flex flex-row items-center hover:underline align-end overflow-scroll hover:cursor-pointer text-[14px]'
                                    onClick={(event) => {
                                        event.stopPropagation();
                                        setSubscriptionModal(true);
                                    }}
                                >
                                    <div className='flex flex-row justify-start w-contain'>
                                        <TwSprkles
                                            className='hover:cursor-pointer fill-cyan-400 dark:hover:fill-white hover:fill-black !inline-block align-middle !w-6 !h-6 mr-2'
                                        />
                                    </div>
                                    Memberships
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <SubComponentPerformanceInterface>
                </SubComponentPerformanceInterface>
            </div >

            <div className="border-b border-solid dark:border-slate-600 border-slate-200 w-[95%] m-auto mb-8 mt-8">
                <nav className="-mb-px flex space-x-2 sm:space-x-4 max-w-full overflow-x-auto no-scrollbar" x-descriptions="Tab component">
                    <span className={`
                        whitespace-nowrap py-2 pb-4 px-2 flex items-center border-solid
                        border-b-2 font-medium focus:outline-none focus:ring-none text-sm
                        hover:cursor-pointer
                        ${user.navTab === 'ByYou' ? (
                            'dark:border-white border-black'
                        ) : (
                            'text-gray-400 dark:text-gray-500 dark:hover:text-white border-gray-500 dark:hover:border-white  hover:text-gray-800 hover:border-black'
                        )}
                        `}
                        onClick={() => {
                            dispatch(setnavTab('ByYou'))
                        }}
                    >
                        <span>Made by you</span>
                    </span>
                    <span className={`
                        whitespace-nowrap py-2 pb-4 px-2 flex items-center border-solid
                        border-b-2 font-medium focus:outline-none focus:ring-none text-sm
                        hover:cursor-pointer
                        ${user.navTab === 'Saved' ? (
                            'dark:border-white border-black'
                        ) : (
                            'text-gray-400 dark:text-gray-500 dark:hover:text-white border-gray-500 dark:hover:border-white  hover:text-gray-800 hover:border-black'
                        )}
                        `}
                        onClick={() => {
                            dispatch(setnavTab('Saved'))
                        }}
                    >
                        <span>My Library</span>
                    </span>
                    <span className={`
                        whitespace-nowrap py-2 pb-4 px-2 flex items-center border-solid
                        border-b-2 font-medium focus:outline-none focus:ring-none text-sm
                        hover:cursor-pointer
                        ${user.navTab === 'FindCourses' ? (
                            'dark:border-white border-black'
                        ) : (
                            'text-gray-400 dark:text-gray-500 dark:hover:text-white border-gray-500 dark:hover:border-white  hover:text-gray-800 hover:border-black'
                        )}
                        `}
                        onClick={() => {
                            dispatch(setnavTab('FindCourses'))
                        }}
                    >
                        <span>Pre-made Courses</span>
                    </span>
                </nav>
            </div >


            <Modal show={profileModal} onClose={() => setProfileModal(false)}>
                <Modal.Header>Profile | {user.is_guest ? user.userName : user.email}</Modal.Header>
                <Modal.Body>
                    {user.is_affiliate ? (
                        <button
                            type="submit"
                            className="border border-cyan-500 rounded-full w-3/4 text-center py-3 bg-gradient-to-r from-gray-50 to-white focus:outline-none my-1 flex items-center justify-center shadow-lg transform transition-transform duration-200 ease-in-out hover:scale-105 m-auto text-black hover:shadow-cyan-500/50 mt-8"
                        >

                            <div className='flex flex-row w-[90%] justify-between'
                                onClick={() => {
                                    if (!('hid' in user) || !user.hid || !('accessToken' in user && user.accessToken)) {
                                        dispatch(setActiveComponent('SubComponentUserRegistration'));
                                        dispatch(setActiveSubComponent('PrivateComponentSignUp'));
                                    } else {
                                        dispatch(setActiveComponent('SubComponentStripeDashboard'));
                                    }
                                    setProfileModal(false)
                                }}>
                                <TwSprkles
                                    className='hover:cursor-pointer fill-cyan-500 !inline-block align-middle !w-6 !h-6 ml-1'
                                />
                                <span className="font-bold text-baseline">Affiliate Dashboard</span>
                                <TwSprkles
                                    className='hover:cursor-pointer fill-cyan-500 !inline-block align-middle !w-6 !h-6 ml-1'
                                />
                            </div>
                        </button>
                    ) : (null)}
                    <div className='flex flex-row mt-8 w-full justify-end'>
                        <TwButton onClick={() => { dispatch(userLogout()); dispatch(unsetCourseState()); setProfileModal(false); navigate('/'); }} className={`
                                    p-1
                                    px-3
                                    rounded-md
                                    hover:bg-slate-300
                                    dark:hover:bg-slate-700
                                    float-right
                                    ${iconJustify}
                                `}>
                            Logout
                            <FontAwesomeIcon icon={faPersonRunning} className='ml-1' />
                        </TwButton>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <TwButton
                        className={`
                                        p-1
                                        px-3
                                        rounded-md
                                        hover:bg-slate-300
                                        dark:hover:bg-slate-700
                                        float-right
                                    `}
                        onClick={() => setProfileModal(false)}>Close</TwButton>
                </Modal.Footer>
            </Modal>

            <Modal position={'center'} size={'xl'} show={subscriptionModal} onClose={() => setSubscriptionModal(false)}>
                <Modal.Header>Upgrade your learning</Modal.Header>
                <Modal.Body>
                    <div className='flex flex-col w-full items-left min-w-[400px]'>
                        <h2 className='text-2xl font-bold mb-4 text-gray-900 dark:text-white'>Supercharge your experience</h2>
                        <ul className='list-none text-center mb-2 text-gray-700 dark:text-gray-200'>
                            <li className='mb-2 flex justify-left hidden'>
                                <FontAwesomeIcon icon={faCircleCheck} className='text-cyan-500 mr-2' />
                                Increased AI Model Limits
                            </li>
                            <li className='mb-2 flex items-center justify-left'>
                                <FontAwesomeIcon icon={faCircleCheck} className='text-cyan-500 mr-2' />
                                Unlimited MiniLessons
                            </li>
                            <li className='mb-2 flex items-center justify-left'>
                                <FontAwesomeIcon icon={faCircleCheck} className='text-cyan-500 mr-2' />
                                All New Features
                            </li>
                            <li className='ml-4 mb-2 flex items-center justify-left text-gray-400'>
                                <FontAwesomeIcon icon={faCircleCheck} className='text-gray-500 mr-2' />
                                Make your own course (coming soon)
                            </li>
                            <li className='ml-4 mb-2 flex items-center justify-left text-gray-400'>
                                <FontAwesomeIcon icon={faCircleCheck} className='text-gray-500 mr-2' />
                                Everything Upcoming!
                            </li>
                        </ul>
                        <script async
                            src="https://js.stripe.com/v3/buy-button.js">
                        </script>

                        <button
                            type="submit"
                            className="border border-cyan-500 rounded-full w-3/4 text-center py-3 bg-gradient-to-r from-gray-50 to-white focus:outline-none my-1 flex items-center justify-center shadow-lg transform transition-transform duration-200 ease-in-out hover:scale-105 m-auto text-black hover:shadow-cyan-500/50"
                        >
                            <div className='flex flex-row w-[90%] justify-between'
                                onClick={() => {
                                    if (!('hid' in user) || !user.hid || !('accessToken' in user && user.accessToken)) {
                                        dispatch(setActiveComponent('SubComponentUserRegistration'));
                                        dispatch(setActiveSubComponent('PrivateComponentSignUp'));
                                        setSubscriptionModal(false);
                                    } else {
                                        if (user.is_member) {
                                            dispatch(courseGetPortalSession({ accessToken: user.accessToken }))
                                        } else if (user.is_guest) {
                                            dispatch(setActiveComponent('SubComponentUserRegistration'));
                                            dispatch(setActiveSubComponent('PrivateComponentSignUp'));
                                            setSubscriptionModal(false);
                                        } else {
                                            dispatch(courseGetCheckoutSession({ accessToken: user.accessToken }))
                                            dispatch(userLogout())
                                        }
                                        // Do nothing or add an alternative action if needed
                                    }
                                }}>
                                <TwSprkles
                                    className='hover:cursor-pointer fill-cyan-500 !inline-block align-middle !w-6 !h-6 ml-1'
                                />
                                {course.loading.courseGetCheckoutSession || course.loading.courseGetPortalSession ? (
                                    <TwLoading className="font-bold text-baseline" />
                                ) : (
                                    user.is_member ? (
                                        <span className="font-bold text-baseline">Manage Subscription</span>
                                    ) : (
                                        <span className="font-bold text-baseline">Upgrade for £3.99 / Month</span>
                                    )
                                )}
                                <TwSprkles
                                    className='hover:cursor-pointer fill-cyan-500 !inline-block align-middle !w-6 !h-6 ml-1'
                                />
                            </div>
                        </button>
                        <style>{`.hover\\:shadow-cyan-500\\/50:hover {box-shadow: 0 4px 20px rgba(0, 255, 255, 0.5);}`}</style>
                    </div>
                </Modal.Body >
                <Modal.Footer>
                    <TwButton
                        className={`
                                        p-1
                                        px-3
                                        rounded-md
                                        hover:bg-slate-300
                                        dark:hover:bg-slate-700
                                        float-right
                                    `}
                        onClick={() => setSubscriptionModal(false)}>Close</TwButton>
                </Modal.Footer>
            </Modal >
        </>
    );
}
export function TwHorizontalSeparator() {
    return (
        <div
            className={`
				w-11/12
				mt-4
				h-px
				border-t
				border-solid
				mx-auto
				border-slate-800
				dark:border-slate-200
				`}
        >
        </div>
    )
}
export function TwGeneralTutorCardBasic(props: {
    children: React.ReactNode;
    className?: string;
    onClick: Function;
}) {
    const user = useSelector((state: RootState) => state.user)
    const course = useSelector((state: RootState) => state.course)
    return (
        <>
            <Card
                onClick={() => props.onClick()}
                className={`
                            flex flex-row items-center rounded-lg 
                            transform transition-transform duration-250
                            relative

                            dark:bg-slate-700
                            dark:hover:bg-slate-500

                            bg-slate-100
                            hover:bg-slate-200
                            p-1
                            cursor-pointer
                            w-[100%]
                            md:min-w-60
                            md:max-w-60
                            md:overflow-scroll
                            min-h-32
                            max-h-32
                            `}
                theme={
                    {
                        "root": {
                            "children": "flex h-full flex-col justify-center gap-2 p-2",
                            "horizontal": {
                                "off": "flex-col",
                                "on": "flex-col md:max-w-xl md:flex-row"
                            },
                            "href": "hover:bg-gray-100 dark:hover:bg-gray-700"
                        },
                        "img": {
                            "base": "",
                            "horizontal": {
                                "off": "rounded-t-lg",
                                "on": "h-96 w-full rounded-t-lg object-cover md:h-auto md:w-48 md:rounded-none md:rounded-l-lg"
                            }
                        }
                    }
                }
            >
                <div className="w-m-auto flex flex-row w-full">
                    <img
                        src="/ai_logo.jpeg"
                        alt="CourseImage"
                        className="w-7 h-7 aspect-auto object-cover rounded-xl absolute top-2 left-2"
                    />
                </div>
                <div className="m-m-0 text-[12px]0 ml-1 text-[12px]">
                    Your Mini Lessons
                </div>
                {user.hid ? (
                    <>
                        <div className="flex flex-row w-full text-[8px] text-gray-400">
                            <div className="flex flex-row m-m-0 mr-1">
                                <FontAwesomeIcon icon={faBook} className='mr-1' /> {course.generalTutorLessonCounts.total_lessons} Lessons
                            </div>
                            <div className="flex flex-row m-m-0 mr-1">
                                <FontAwesomeIcon icon={faHourglassHalf} className='mr-1' /> {course.generalTutorLessonCounts.total_lessons - course.generalTutorLessonCounts.completed_lessons} Pending
                            </div>
                            <div className="flex flex-row m-m-0 mr-1">
                                <FontAwesomeIcon icon={faCrown} className='mr-1 text-yellow-300' /> {course.generalTutorLessonCounts.completed_lessons} Completed
                            </div>
                        </div>
                        <Progress
                            progress={((course.generalTutorLessonCounts.completed_lessons) / (course.generalTutorLessonCounts.total_lessons)) * 100}
                            size="sm"
                            color='dark'
                            className='mb-1 mt-2 w-full absolute bottom-1 left-2 w-[95%]'
                            theme={
                                {
                                    "base": "w-full overflow-hidden rounded-full bg-gray-200 dark:bg-slate-800",
                                    "label": "mb-1 flex justify-between font-medium dark:text-white",
                                    "bar": "space-x-2 rounded-full text-center font-medium leading-none text-cyan-300 dark:text-cyan-100",
                                    "color": {
                                        "dark": "bg-gray-600 dark:bg-gray-300",
                                        "blue": "bg-blue-600",
                                        "red": "bg-red-600 dark:bg-red-500",
                                        "green": "bg-green-600 dark:bg-green-500",
                                        "yellow": "bg-yellow-400",
                                        "indigo": "bg-indigo-600 dark:bg-indigo-500",
                                        "purple": "bg-purple-600 dark:bg-purple-500",
                                        "cyan": "bg-cyan-600",
                                        "gray": "bg-gray-500",
                                        "lime": "bg-lime-600",
                                        "pink": "bg-pink-500",
                                        "teal": "bg-teal-600"
                                    },
                                    "size": {
                                        "sm": "h-1.5",
                                        "md": "h-2.5",
                                        "lg": "h-4",
                                        "xl": "h-6"
                                    }
                                }
                            }
                        />
                    </>
                ) : (null)}
            </Card>
            {!user.hid || course.generalTutorCourseSubscriptionList.length > 0 ? (
                course.generalTutorCourseSubscriptionList.map((subscription, idx) => (
                    <TwGeneralTutorCourseCardBasic
                        key={`GeneralTutorcourses_${idx}`}
                        className='p-1'
                        subscription={subscription}

                        hid={subscription.course.hid}
                        icon_url={subscription.course.icon_url}
                    >
                        {subscription.course.name}
                    </TwGeneralTutorCourseCardBasic>
                ))
            ) : (null)}
        </>
    )
}
export function TwGeneralTutorCourseCardBasic(props: {
    children: React.ReactNode,
    subscription: any,
    hid: string,
    icon_url?: string;
    className: string,
}) {
    const user = useSelector((state: RootState) => state.user)
    const course = useSelector((state: RootState) => state.course)
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate()
    const [openOptionModal, setOpenOptionModal] = useState(false)
    //
    let lesson_number = props.subscription?.lnum
    if (!lesson_number) {
        lesson_number = '1_1'
    }
    const count_lessons_completed: any = Object.values(props.subscription.progress_track).reduce((acc: any, obj: any) => {
        // Check if any values in the object match the target value
        return acc + Object.values(obj).filter(val => val === 2).length;
    }, 0);
    return (
        <>
            <Card
                className={`
				flex flex-row items-center rounded-lg 
				transform transition-transform duration-250

				dark:bg-slate-700
				dark:hover:bg-slate-500

				bg-slate-100
				hover:bg-slate-200
				p-1
                cursor-pointer
                w-[100%]
                md:min-w-60
                md:max-w-60
                md:overflow-scroll
                min-h-32
                max-h-32
				${props.className}
				`}
                theme={
                    {
                        "root": {
                            "children": "flex h-full flex-col justify-center gap-2 p-2",
                            "horizontal": {
                                "off": "flex-col",
                                "on": "flex-col md:max-w-xl md:flex-row"
                            },
                            "href": "hover:bg-gray-100 dark:hover:bg-gray-700"
                        },
                        "img": {
                            "base": "",
                            "horizontal": {
                                "off": "rounded-t-lg",
                                "on": "h-96 w-full rounded-t-lg object-cover md:h-auto md:w-48 md:rounded-none md:rounded-l-lg"
                            }
                        }
                    }
                }
                onClick={() => {
                    if (props.subscription.course.completed) {
                        dispatch(setActiveComponent('SubComponentChatInterface'))
                        navigate(`/chat?cid=${props.hid}&lnum=${lesson_number}`)
                    } else {
                        toast.info('Refresh and try again, this takes a few minutes!')
                    }
                }}
            >
                <div className='w-[50px] min-w-[50px] absolute top-2 right-2 text-end'>
                    <FontAwesomeIcon
                        icon={faEllipsisVertical}
                        size="lg"
                        className='
                                w-4 h-5
                                hover:cursor-pointer
                                dark:text-slate-300 dark:hover:text-slate-800
                                text-slate-400 hover:text-slate-800
                                ml-auto
                            '
                        onClick={(event) => {
                            event.preventDefault();
                            event.stopPropagation();
                            setOpenOptionModal(true);
                        }}
                    />
                </div>
                <div className="w-m-auto flex flex-row w-full">
                    <img
                        src={props.icon_url ? props.icon_url : "/courses_placeholder.jpeg"}
                        alt="CourseImage"
                        className="w-7 h-7 aspect-auto object-cover rounded-xl absolute top-2 left-2"
                    />
                </div>
                <div className="m-m-0 ml-1 text-[12px]0 ml-1 text-[12px]">
                    {props.children}
                </div>
                {!props.subscription.course.completed ? (
                    <div className="flex flex-row m-m-0 mr-1">
                        <FontAwesomeIcon icon={faHourglassHalf} className='mr-1' /> Please Wait...
                    </div>
                ) : (
                    <>
                        <div className="flex flex-row w-full text-[8px] text-gray-400">
                            <div className="flex flex-row m-m-0 mr-1">
                                <FontAwesomeIcon icon={faBook} className='mr-1' /> {props.subscription.course.n_lessons} Lessons
                            </div>
                            <div className="flex flex-row m-m-0 mr-1">
                                <FontAwesomeIcon icon={faHourglassHalf} className='mr-1' /> {String(props.subscription.course.n_lessons - count_lessons_completed)} Pending
                            </div>
                            <div className="flex flex-row m-m-0 mr-1">
                                <FontAwesomeIcon icon={faCrown} className='mr-1 text-yellow-300' /> {String(count_lessons_completed)} Completed
                            </div>
                        </div>
                        <Progress
                            progress={(Number(count_lessons_completed)) / (Number(props.subscription.course.n_lessons)) * 100}
                            size="sm"
                            color='dark'
                            className='mb-1 mt-2 w-full absolute bottom-1 left-2 w-[95%]'
                            theme={
                                {
                                    "base": "w-full overflow-hidden rounded-full bg-gray-200 dark:bg-slate-800",
                                    "label": "mb-1 flex justify-between font-medium dark:text-white",
                                    "bar": "space-x-2 rounded-full text-center font-medium leading-none text-cyan-300 dark:text-cyan-100",
                                    "color": {
                                        "dark": "bg-gray-600 dark:bg-gray-300",
                                        "blue": "bg-blue-600",
                                        "red": "bg-red-600 dark:bg-red-500",
                                        "green": "bg-green-600 dark:bg-green-500",
                                        "yellow": "bg-yellow-400",
                                        "indigo": "bg-indigo-600 dark:bg-indigo-500",
                                        "purple": "bg-purple-600 dark:bg-purple-500",
                                        "cyan": "bg-cyan-600",
                                        "gray": "bg-gray-500",
                                        "lime": "bg-lime-600",
                                        "pink": "bg-pink-500",
                                        "teal": "bg-teal-600"
                                    },
                                    "size": {
                                        "sm": "h-1.5",
                                        "md": "h-2.5",
                                        "lg": "h-4",
                                        "xl": "h-6"
                                    }
                                }
                            }
                        />
                    </>
                )}
            </Card>
            <Modal
                show={openOptionModal}
                size={'sm'}
                onClose={() => setOpenOptionModal(false)}
                theme={{ header: { title: "text-xl font-medium text-gray-900 dark:text-white basis-10/12" } }}
                position={'center'}
            >
                <Modal.Header>
                    Course: {props.subscription.course.name}
                </Modal.Header>
                <Modal.Body>
                    <div className='flex flex-row justify-center'>
                        {course.loading.generalTutorDeleteCourse ? (
                            <TwLoading />
                        ) : (
                            <TwButton
                                onClick={() => {
                                    if (user.accessToken) {
                                        dispatch(generalTutorDeleteCourse({ subscription_hid: props.subscription.hid, course_hid: props.subscription.course.hid, accessToken: user.accessToken }));
                                        setTimeout(function() {
                                            setOpenOptionModal(false)
                                        }, 300);
                                    }
                                }
                                }

                                className='bg-red-400'
                            >
                                Delete Course
                            </TwButton>
                        )}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div>
                        Everything will be erased!
                    </div>
                </Modal.Footer>
            </Modal>
        </>
    )
}
export function TwCourseCardBasic(props: {
    children: React.ReactNode,
    subscription: any,
    hid: string,
    icon_url?: string;
    className: string,
}) {
    const user = useSelector((state: RootState) => state.user)
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate()
    const [openOptionModal, setOpenOptionModal] = useState(false)
    //
    const triggerSaveCourseSubscription = (course_hid: string, saved_status: boolean) => {
        if (user.accessToken) {
            dispatch(courseToggleSaveCourseSubscription({ cid: course_hid, saved_status: saved_status, accessToken: user.accessToken }))
        }
    }
    let lesson_number = props.subscription?.lnum
    if (!lesson_number) {
        lesson_number = '1_1'
    }
    const count_lessons_completed: any = Object.values(props.subscription.progress_track).reduce((acc: any, obj: any) => {
        // Check if any values in the object match the target value
        return acc + Object.values(obj).filter(val => val === 2).length;
    }, 0);
    return (
        <>
            <Card
                className={`
				flex flex-row items-center rounded-lg 
				transform transition-transform duration-250

				dark:bg-slate-700
				dark:hover:bg-slate-500

				bg-slate-100
				hover:bg-slate-200
				p-1
                cursor-pointer
                w-[100%]
                md:min-w-60
                md:max-w-60
                md:overflow-scroll
                min-h-32
                max-h-32
				${props.className}
				`}
                theme={
                    {
                        "root": {
                            "children": "flex h-full flex-col justify-center gap-2 p-2",
                            "horizontal": {
                                "off": "flex-col",
                                "on": "flex-col md:max-w-xl md:flex-row"
                            },
                            "href": "hover:bg-gray-100 dark:hover:bg-gray-700"
                        },
                        "img": {
                            "base": "",
                            "horizontal": {
                                "off": "rounded-t-lg",
                                "on": "h-96 w-full rounded-t-lg object-cover md:h-auto md:w-48 md:rounded-none md:rounded-l-lg"
                            }
                        }
                    }
                }
                onClick={() => {
                    dispatch(setActiveComponent('SubComponentChatInterface'))
                    navigate(`/chat?cid=${props.hid}&lnum=${lesson_number}`)
                }}
            >
                <div className='w-[50px] min-w-[50px] absolute top-2 right-2 text-end'>
                    <FontAwesomeIcon
                        icon={faEllipsisVertical}
                        size="lg"
                        className='
                                w-4 h-5
                                hover:cursor-pointer
                                dark:text-slate-300 dark:hover:text-slate-800
                                text-slate-400 hover:text-slate-800
                                ml-auto
                            '
                        onClick={(event) => {
                            event.preventDefault();
                            event.stopPropagation();
                            setOpenOptionModal(true);
                        }}
                    />
                </div>
                <div className="w-m-auto flex flex-row w-full">
                    <img
                        src={props.icon_url ? props.icon_url : "/courses_placeholder.jpeg"}
                        alt="CourseImage"
                        className="w-7 h-7 aspect-auto object-cover rounded-xl absolute top-2 left-2"
                    />
                </div>
                <div className="m-m-0 ml-1 text-[12px]0 ml-1 text-[12px]">
                    {props.children}
                </div>
                <div className="flex flex-row w-full text-[8px] text-gray-400">
                    <div className="flex flex-row m-m-0 mr-1">
                        <FontAwesomeIcon icon={faBook} className='mr-1' /> {props.subscription.course.n_lessons} Lessons
                    </div>
                    <div className="flex flex-row m-m-0 mr-1">
                        <FontAwesomeIcon icon={faHourglassHalf} className='mr-1' /> {String(props.subscription.course.n_lessons - count_lessons_completed)} Pending
                    </div>
                    <div className="flex flex-row m-m-0 mr-1">
                        <FontAwesomeIcon icon={faCrown} className='mr-1 text-yellow-300' /> {String(count_lessons_completed)} Completed
                    </div>
                </div>
                <Progress
                    progress={(Number(count_lessons_completed)) / (Number(props.subscription.course.n_lessons)) * 100}
                    size="sm"
                    color='dark'
                    className='mb-1 mt-2 w-full absolute bottom-1 left-2 w-[95%]'
                    theme={
                        {
                            "base": "w-full overflow-hidden rounded-full bg-gray-200 dark:bg-slate-800",
                            "label": "mb-1 flex justify-between font-medium dark:text-white",
                            "bar": "space-x-2 rounded-full text-center font-medium leading-none text-cyan-300 dark:text-cyan-100",
                            "color": {
                                "dark": "bg-gray-600 dark:bg-gray-300",
                                "blue": "bg-blue-600",
                                "red": "bg-red-600 dark:bg-red-500",
                                "green": "bg-green-600 dark:bg-green-500",
                                "yellow": "bg-yellow-400",
                                "indigo": "bg-indigo-600 dark:bg-indigo-500",
                                "purple": "bg-purple-600 dark:bg-purple-500",
                                "cyan": "bg-cyan-600",
                                "gray": "bg-gray-500",
                                "lime": "bg-lime-600",
                                "pink": "bg-pink-500",
                                "teal": "bg-teal-600"
                            },
                            "size": {
                                "sm": "h-1.5",
                                "md": "h-2.5",
                                "lg": "h-4",
                                "xl": "h-6"
                            }
                        }
                    }
                />
            </Card>
            <Modal
                show={openOptionModal}
                size={'sm'}
                onClose={() => setOpenOptionModal(false)}
                theme={{ header: { title: "text-xl font-medium text-gray-900 dark:text-white basis-10/12" } }}
                position={'center'}
            >
                <Modal.Header>
                    Course: {props.subscription.course.name}
                </Modal.Header>
                <Modal.Body>
                    <div className='flex flex-row justify-center'>
                        <TwButton
                            onClick={() => { triggerSaveCourseSubscription(props.hid, false) }}
                            className='bg-red-400'
                        >
                            Unsave Course
                        </TwButton>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div>
                        Your progress will not be erased.
                    </div>
                </Modal.Footer>
            </Modal>
        </>
    )
}
export function TwCourseCardBasicDisplay(props: {
    children: React.ReactNode,
    hid: string,
    icon_url?: string;
    className?: string,
    numberSubscriptions?: number;
    numberChats?: string;
}) {
    const user = useSelector((state: RootState) => state.user)
    const course = useSelector((state: RootState) => state.course)
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate()
    //
    const triggerSaveCourseSubscription = (course_hid: string, saved_status: boolean) => {
        if (user.accessToken) {
            dispatch(courseToggleSaveCourseSubscription({ cid: course_hid, saved_status: saved_status, accessToken: user.accessToken }))
        }
    }
    let lesson_number = '1_1'
    return (
        <div
            className={`
				flex flex-row items-center w-full rounded-lg 
				transform transition-transform duration-250

				dark:bg-slate-600
				dark:hover:bg-slate-500

                bg-slate-100
                hover:bg-slate-200
				p-1
                cursor-pointer
                h-10
                text-l
				${props.className}
				`}
            onClick={() => {
                dispatch(setActiveComponent('SubComponentChatInterface'))
                navigate(`/chat?cid=${props.hid}&lnum=${lesson_number}`)
            }}
        >
            <div className="m-0 ml-1 text-[12px]">
                {props.children}
            </div>
            <div className='ml-auto flex flex-row'>
                <div className='mr-1 min-w[40px] w-[40px] ml-2 text-[10px]'>
                    <FontAwesomeIcon icon={faGraduationCap} className='mr-1 w-3 h-3' />{props.numberSubscriptions} <br />
                </div>
                <div className='mr-1 min-w[40px] w-[40px] text-[10px]'>
                    <FontAwesomeIcon icon={faChartLine} className='mr-1 w-3 h-3' />{props.numberChats} <br />
                </div>
                {user.hid && !(course.subscriptionCourses.some(item => item.course.hid === props.hid)) ? (
                    <div className='w-[40px] min-w-[40px] flex flex-col justify-center align-right mr-1'>
                        <FontAwesomeIcon
                            icon={faSquarePlus}
                            size="lg"
                            className='
                            w-4 h-4
                            hover:cursor-pointer
                            dark:text-green-500 dark:hover:text-green-300
                            text-green-600 hover:text-green-400
                            ml-auto
                            '
                            onClick={(event) => {
                                event.preventDefault();
                                event.stopPropagation();
                                triggerSaveCourseSubscription(props.hid, true)
                            }}
                        />
                    </div>
                ) : (user.hid ? (
                    <div className='w-[30px] min-w-[30px] flex flex-col justify-center align-right mr-1'>
                        <FontAwesomeIcon
                            icon={faCheck}
                            size="lg"
                            className='
                            w-4 h-4
                            hover:cursor-pointer
                            dark:text-gray-300 dark:hover:text-gray-800
                            text-gray-600 hover:text-gray-400
                            ml-auto
                            '

                        />
                    </div>
                ) : (null)
                )}
            </div>
        </div>
    )
}
export function TwCourseCardAdvanced(props: {
    children?: React.ReactNode;
    hid?: string;
    icon_url?: string;
    className?: string;
    numberSubscriptions?: number;
    numberChats?: string;
}) {
    //
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate()
    let lesson_number = '1_1'
    return (
        <div
            className="flex flex-col w-52 min-h-full px-6 py-4 rounded-lg transform transition-transform duration-250 bg-slate-200 hover:bg-slate-300 dark:bg-slate-900 dark:hover:bg-slate-700 cursor-pointer"
            onClick={() => {
                dispatch(setActiveComponent('SubComponentChatInterface'));
                navigate(`/chat?cid=${props.hid}&lnum=${lesson_number}`);
            }}
        >
            <div className="w-m-auto">
                <img
                    src={props.icon_url ? props.icon_url : "/courses_placeholder.jpeg"}
                    alt="CourseImage"
                    className="aspect-auto object-cover rounded-xl"
                />
            </div>
            <div className="m-auto mb-3">
                {props.children}
            </div>
            <div className="flex flex-row justify-between mb-1 text-xs">
                <div>
                    <FontAwesomeIcon icon={faGraduationCap} className='mr-1' />{props.numberSubscriptions} <br />
                </div>
                <div>
                    <FontAwesomeIcon icon={faComments} className='mr-1' />{props.numberChats} <br />
                </div>
            </div>
        </div>
    )
}
export function TwPointUI(props: {
    thread_index: number;
    pair_index: number | undefined;
    is_general_tutor?: boolean;
}) {
    //
    const course = useSelector((state: RootState) => state.course)
    //
    let chatThread = null
    if (props.is_general_tutor) {
        chatThread = 'chat_threads' in course.generalTutorLesson ? course?.generalTutorLesson?.chat_threads[props.thread_index] : null
    } else {
        chatThread = 'chat_threads' in course.courseLesson ? course?.courseLesson?.chat_threads[props.thread_index] : null
    }
    let chatItem = null
    let message: any = ''
    if (chatThread) {
        if (props.pair_index === undefined) {
            chatItem = chatThread[0]
        } else {
            chatItem = chatThread[1][props.pair_index][0]
        }
        message = 'message' in chatItem ? chatItem.message ?? null : null
    }
    var parsed_message = myjsonrepair(message)
    let point = parsed_message ? JSON.parse(parsed_message) : null
    //
    if (!point) {
        return null
    }
    return (
        <>
            {point !== undefined ? (
                <>
                    {'lesson' in point ? (
                        <Markdown remarkPlugins={[remarkMath, remarkGfm]} rehypePlugins={[rehypeKatex]}>{point.lesson ? point.lesson.replace(/\\\\/g, '\\') : point.lesson}</Markdown>
                    ) : (null)}
                </>
            ) : (null)}
        </>
    )
}
export function TwNormalPromptUI(props: {
    thread_index: number;
    pair_index: number | undefined;
    is_general_tutor?: boolean;
}) {
    //
    const course = useSelector((state: RootState) => state.course)
    //
    let normalResponse = null
    let chatThread = null
    if (props.is_general_tutor) {
        chatThread = 'chat_threads' in course.generalTutorLesson ? course?.generalTutorLesson?.chat_threads[props.thread_index] : null
    } else {
        chatThread = 'chat_threads' in course.courseLesson ? course?.courseLesson?.chat_threads[props.thread_index] : null
    }
    if (chatThread && typeof props.pair_index === 'number') {
        let chatItem = chatThread[1][props.pair_index][1]
        normalResponse = chatItem.message
    }
    //
    if (!normalResponse) {
        return null
    }
    return (
        <>
            <Markdown remarkPlugins={[remarkMath, remarkGfm]} rehypePlugins={[rehypeKatex]}>{normalResponse}</Markdown>
        </>
    )
}
export function TwNewAssessmentUI(props: { setAssessmentModal: CallableFunction }) {
    //
    const user = useSelector((state: RootState) => state.user)
    const course = useSelector((state: RootState) => state.course)
    const dispatch = useDispatch<AppDispatch>();
    //
    if (('courseLesson' in course) && !('hid' in course.courseLesson)) {
        return null
    }
    return (
        <>
            <div className='flex flex-row w-12/12 m-auto justify-center mt-2 flex-wrap'>
                <TwButton
                    className='w-28 mt-2'
                    onClick={() => {
                        if (
                            !course.loading.streaming &&
                            'courseLesson' in course &&
                            'hid' in course.courseLesson &&
                            'activeThread' in course &&
                            typeof course.activeThread === 'number' &&
                            typeof user.accessToken === 'string' &&
                            'accessToken' in user
                        ) {
                            props.setAssessmentModal(true);
                            if (
                                !course.courseAssessment ||
                                (course.courseAssessment && !('lesson_hid' in course.courseAssessment)) ||
                                (
                                    course.courseAssessment && 'lesson_hid' in course.courseAssessment &&
                                    (
                                        course!.courseAssessment!.lesson_hid !== course!.courseLesson!.hid ||
                                        course.courseAssessment.assessment_type !== 'FlashCards' ||
                                        course.courseAssessment.thread_index !== course.activeThread
                                    )
                                )
                            ) {
                                dispatch(courseGetAssessment({
                                    lesson_hid: course.courseLesson.hid,
                                    thread_index: course.activeThread,
                                    assessment_type: 'FlashCards',
                                    accessToken: user.accessToken,
                                }));
                            }
                        }
                    }}
                    disabled={course.loading.streaming}
                >
                    FlashCards
                </TwButton>
                <TwButton
                    className='w-28 mt-2'
                    onClick={() => {
                        if (
                            !course.loading.streaming &&
                            'courseLesson' in course &&
                            'hid' in course.courseLesson &&
                            'activeThread' in course &&
                            typeof course.activeThread === 'number' &&
                            typeof user.accessToken === 'string' &&
                            'accessToken' in user
                        ) {
                            props.setAssessmentModal(true);
                            if (
                                !course.courseAssessment ||
                                (course.courseAssessment && !('lesson_hid' in course.courseAssessment)) ||
                                (
                                    course.courseAssessment && 'lesson_hid' in course.courseAssessment &&
                                    (
                                        course!.courseAssessment!.lesson_hid !== course!.courseLesson!.hid ||
                                        course.courseAssessment.assessment_type !== 'Quiz' ||
                                        course.courseAssessment.thread_index !== course.activeThread
                                    )
                                )
                            ) {
                                dispatch(courseGetAssessment({
                                    lesson_hid: course.courseLesson.hid,
                                    thread_index: course.activeThread,
                                    assessment_type: 'Quiz',
                                    accessToken: user.accessToken,
                                }));
                            }
                        }
                    }}

                    disabled={course.loading.streaming}
                >
                    Quiz
                </TwButton>
                <TwButton
                    className='w-28 mt-2'
                    onClick={() => {
                        if (
                            !course.loading.streaming &&
                            'courseLesson' in course &&
                            'hid' in course.courseLesson &&
                            'activeThread' in course &&
                            typeof course.activeThread === 'number' &&
                            typeof user.accessToken === 'string' &&
                            'accessToken' in user
                        ) {
                            props.setAssessmentModal(true);
                            if (
                                !course.courseAssessment ||
                                (course.courseAssessment && !('lesson_hid' in course.courseAssessment)) ||
                                (
                                    course.courseAssessment && 'lesson_hid' in course.courseAssessment &&
                                    (
                                        course!.courseAssessment!.lesson_hid !== course!.courseLesson!.hid ||
                                        course.courseAssessment.assessment_type !== 'ClozeCards' ||
                                        course.courseAssessment.thread_index !== course.activeThread
                                    )
                                )
                            ) {
                                dispatch(courseGetAssessment({
                                    lesson_hid: course.courseLesson.hid,
                                    thread_index: course.activeThread,
                                    assessment_type: 'ClozeCards',
                                    accessToken: user.accessToken,
                                }));
                            }
                        }
                    }}

                    disabled={course.loading.streaming}
                >
                    ClozeCards
                </TwButton>
                <TwButton
                    className='w-28 mt-2'
                    onClick={() => {
                        if (
                            !course.loading.streaming &&
                            'courseLesson' in course &&
                            'hid' in course.courseLesson &&
                            'activeThread' in course &&
                            typeof course.activeThread === 'number' &&
                            typeof user.accessToken === 'string' &&
                            'accessToken' in user
                        ) {
                            props.setAssessmentModal(true);
                            if (
                                !course.courseAssessment ||
                                (course.courseAssessment && !('lesson_hid' in course.courseAssessment)) ||
                                (
                                    course.courseAssessment && 'lesson_hid' in course.courseAssessment &&
                                    (
                                        course!.courseAssessment!.lesson_hid !== course!.courseLesson!.hid ||
                                        course.courseAssessment.assessment_type !== 'Vocabulary' ||
                                        course.courseAssessment.thread_index !== course.activeThread
                                    )
                                )
                            ) {
                                dispatch(courseGetAssessment({
                                    lesson_hid: course.courseLesson.hid,
                                    thread_index: course.activeThread,
                                    assessment_type: 'Vocabulary',
                                    accessToken: user.accessToken,
                                }));
                            }
                        }
                    }}
                    disabled={course.loading.streaming}
                >
                    Vocabulary
                </TwButton>
            </div >
        </>
    )
}
export function TwGeneralTutorNewAssessmentUI(props: { setAssessmentModal: CallableFunction }) {
    //
    const user = useSelector((state: RootState) => state.user)
    const course = useSelector((state: RootState) => state.course)
    const dispatch = useDispatch<AppDispatch>();
    //
    if (('generalTutorLesson' in course) && !('hid' in course.generalTutorLesson)) {
        return null
    }
    return (
        <>
            <div className='flex flex-row w-12/12 m-auto justify-center mt-2 flex-wrap'>
                <TwButton
                    className='w-28 mt-2'
                    onClick={() => {
                        if (
                            !course.loading.streaming &&
                            'generalTutorLesson' in course &&
                            'hid' in course.generalTutorLesson &&
                            'activeGeneralTutorThread' in course &&
                            typeof course.activeGeneralTutorThread === 'number' &&
                            typeof user.accessToken === 'string' &&
                            'accessToken' in user
                        ) {
                            props.setAssessmentModal(true);
                            if (
                                !course.generalTutorAssessment ||
                                (course.generalTutorAssessment && !('lesson_hid' in course.generalTutorAssessment)) ||
                                (
                                    course.generalTutorAssessment && 'lesson_hid' in course.generalTutorAssessment &&
                                    (
                                        course!.generalTutorAssessment!.lesson_hid !== course!.generalTutorLesson!.hid ||
                                        course.generalTutorAssessment.assessment_type !== 'FlashCards' ||
                                        course.generalTutorAssessment.thread_index !== course.activeGeneralTutorThread
                                    )
                                )
                            ) {
                                dispatch(generalTutorGetAssessment({
                                    lesson_hid: course.generalTutorLesson.hid,
                                    thread_index: course.activeGeneralTutorThread,
                                    assessment_type: 'FlashCards',
                                    accessToken: user.accessToken,
                                }));
                            }
                        }
                    }}
                    disabled={course.loading.streaming}
                >
                    FlashCards
                </TwButton>
                <TwButton
                    className='w-28 mt-2'
                    onClick={() => {
                        if (
                            !course.loading.streaming &&
                            'generalTutorLesson' in course &&
                            'hid' in course.generalTutorLesson &&
                            'activeGeneralTutorThread' in course &&
                            typeof course.activeGeneralTutorThread === 'number' &&
                            typeof user.accessToken === 'string' &&
                            'accessToken' in user
                        ) {
                            props.setAssessmentModal(true);
                            if (
                                !course.generalTutorAssessment ||
                                (course.generalTutorAssessment && !('lesson_hid' in course.generalTutorAssessment)) ||
                                (
                                    course.generalTutorAssessment && 'lesson_hid' in course.generalTutorAssessment &&
                                    (
                                        course!.generalTutorAssessment!.lesson_hid !== course!.generalTutorLesson!.hid ||
                                        course.generalTutorAssessment.assessment_type !== 'Quiz' ||
                                        course.generalTutorAssessment.thread_index !== course.activeGeneralTutorThread
                                    )
                                )
                            ) {
                                dispatch(generalTutorGetAssessment({
                                    lesson_hid: course.generalTutorLesson.hid,
                                    thread_index: course.activeGeneralTutorThread,
                                    assessment_type: 'Quiz',
                                    accessToken: user.accessToken,
                                }));
                            }
                        }
                    }}
                    disabled={course.loading.streaming}
                >
                    Quiz
                </TwButton>
                <TwButton
                    className='w-28 mt-2'
                    onClick={() => {
                        if (
                            !course.loading.streaming &&
                            'generalTutorLesson' in course &&
                            'hid' in course.generalTutorLesson &&
                            'activeGeneralTutorThread' in course &&
                            typeof course.activeGeneralTutorThread === 'number' &&
                            typeof user.accessToken === 'string' &&
                            'accessToken' in user
                        ) {
                            props.setAssessmentModal(true);
                            if (
                                !course.generalTutorAssessment ||
                                (course.generalTutorAssessment && !('lesson_hid' in course.generalTutorAssessment)) ||
                                (
                                    course.generalTutorAssessment && 'lesson_hid' in course.generalTutorAssessment &&
                                    (
                                        course!.generalTutorAssessment!.lesson_hid !== course!.generalTutorLesson!.hid ||
                                        course.generalTutorAssessment.assessment_type !== 'ClozeCards' ||
                                        course.generalTutorAssessment.thread_index !== course.activeGeneralTutorThread
                                    )
                                )
                            ) {
                                dispatch(generalTutorGetAssessment({
                                    lesson_hid: course.generalTutorLesson.hid,
                                    thread_index: course.activeGeneralTutorThread,
                                    assessment_type: 'ClozeCards',
                                    accessToken: user.accessToken,
                                }));
                            }
                        }
                    }}
                    disabled={course.loading.streaming}
                >
                    ClozeCards
                </TwButton>
                <TwButton
                    className='w-28 mt-2'
                    onClick={() => {
                        if (
                            !course.loading.streaming &&
                            'generalTutorLesson' in course &&
                            'hid' in course.generalTutorLesson &&
                            'activeGeneralTutorThread' in course &&
                            typeof course.activeGeneralTutorThread === 'number' &&
                            typeof user.accessToken === 'string' &&
                            'accessToken' in user
                        ) {
                            props.setAssessmentModal(true);
                            if (
                                !course.generalTutorAssessment ||
                                (course.generalTutorAssessment && !('lesson_hid' in course.generalTutorAssessment)) ||
                                (
                                    course.generalTutorAssessment && 'lesson_hid' in course.generalTutorAssessment &&
                                    (
                                        course!.generalTutorAssessment!.lesson_hid !== course!.generalTutorLesson!.hid ||
                                        course.generalTutorAssessment.assessment_type !== 'Vocabulary' ||
                                        course.generalTutorAssessment.thread_index !== course.activeGeneralTutorThread
                                    )
                                )
                            ) {
                                dispatch(generalTutorGetAssessment({
                                    lesson_hid: course.generalTutorLesson.hid,
                                    thread_index: course.activeGeneralTutorThread,
                                    assessment_type: 'Vocabulary',
                                    accessToken: user.accessToken,
                                }));
                            }
                        }
                    }}
                    disabled={course.loading.streaming}
                >
                    Vocabulary
                </TwButton>
            </div>
        </>
    )
}
export function TwFlashcardItem(props: {
    key: string,
    idx: number,
    is_general_tutor?: boolean,
    is_revision?: boolean,
    keys: string[],
    display: number,
    setDisplay: CallableFunction,
    updateSelection: CallableFunction,
    showAnswer: number | boolean,
    setShowAnswer: CallableFunction,
    final_marking: boolean
}) {
    const course = useSelector((state: RootState) => state.course)
    const dispatch = useDispatch<AppDispatch>();
    //
    let assessment: any = null
    let flashcard = null
    let userInputs = null

    if (props.is_general_tutor) {
        if (
            course.generalTutorAssessment
        ) {
            assessment = course.generalTutorAssessment
            if ('content' in assessment && 'flashcards' in assessment.content) {
                flashcard = assessment?.content?.flashcards[props.idx]
                userInputs = assessment?.content?.user_input ? assessment?.content?.user_input[props.idx] : null
            }
        }
    } else {
        if (
            course.courseAssessment
        ) {
            assessment = course.courseAssessment
            if ('content' in assessment && 'flashcards' in assessment.content) {
                flashcard = assessment?.content?.flashcards[props.idx]
                userInputs = assessment?.content?.user_input ? assessment?.content?.user_input[props.idx] : null
            }
        }
    }
    if (props.is_revision) {
        userInputs = assessment?.content?.user_revision_input ? assessment?.content?.user_revision_input : null
    } else {
        userInputs = assessment?.content?.user_input ? assessment?.content?.user_input : null
    }
    //
    const activeCatagoryStyle = 'bg-slate-900 text-slate-50 break-normal dark:!bg-slate-50 dark:text-slate-900'
    if (!assessment || !flashcard) {
        return null
    }
    return (
        <>
            <div
                className={`
                    block
                    mt-1
                    m-auto
                    h-full
                    w-[100%]
                    md:w-[28rem]
                    p-4
                    bg-slate-50
                    border
                    border-slate-200
                    rounded-lg
                    shadow
                    hover:bg-slate-100
                    dark:bg-slate-800
                    dark:border-slate-700
                    dark:hover:bg-slate-700
                    
                    ${(Number(props.display) !== Number(props.idx) ? ('hidden') : (null))}
                    `}
                key={`flashcard_${props.idx}`}
            >
                <h1
                    className="
                    text-center
                    text-xl
                    font-bold
                    tracking-tight
                    text-slate-900
                    dark:text-white
                    "
                >
                    <FontAwesomeIcon icon={faGripLinesVertical} className='text-yellow-300 mr-1' />
                    {props.idx}/{props.keys.length}:
                    {!props.showAnswer ? (' Question') : (' Answer')} side
                    {course.loading.streaming ? <TwPulsing className='ml-4' /> : null}
                </h1>
                {!props.showAnswer ? (
                    <>
                        <div className={`m-auto text-center`}>
                            {!props.showAnswer ? (
                                <TwButton
                                    className='mr-3 text-xl'
                                    onClick={() => { props.setDisplay(props.display - 1); props.setShowAnswer(false) }}
                                >
                                    <FontAwesomeIcon icon={faAnglesLeft} />
                                </TwButton>
                            ) : (null)}
                            <TwButton
                                onClick={() => { props.setShowAnswer(props.showAnswer === false) }}
                            >
                                Show/Hide
                            </TwButton>
                            {!props.showAnswer ? (
                                <TwButton
                                    className='ml-3 text-xl'
                                    onClick={() => { props.setDisplay(props.display + 1); props.setShowAnswer(false) }}
                                >
                                    <FontAwesomeIcon icon={faAnglesRight} />
                                </TwButton>
                            ) : (null)}
                        </div>
                        <Markdown
                            className='
                            mb-8
                            text-lg
                            font-bold
                            tracking-tight
                            text-slate-900 dark:text-white
                            mt-4
                            '
                            remarkPlugins={[remarkMath, remarkGfm]}
                            rehypePlugins={[rehypeKatex]}
                        >
                            {flashcard?.frontside_question}
                        </Markdown>
                    </>
                ) : (
                    <>
                        <div
                            className={`
                            m-auto
                            text-center
                            ${(Number(props.display) !== Number(props.idx) ? ('hidden') : (null))} mb-3`
                            }
                        >
                            <TwButton
                                className='mr-3 text-xl'
                                onClick={() => {
                                    props.setDisplay(props.display - 1);
                                    props.setShowAnswer(false)
                                }}
                            >
                                <FontAwesomeIcon icon={faAnglesLeft} />
                            </TwButton>
                            <TwButton
                                className={`${userInputs !== null && Number(userInputs[props.idx]) === 0 ? (activeCatagoryStyle) : (null)}`}
                                onClick={() => {
                                    props.updateSelection(props.idx, 0);
                                    dispatch(setFlashCardUserInputs({ item_index: props.idx, input_value: 0, is_general_tutor: props.is_general_tutor }));
                                }}
                                disabled={props.final_marking}
                            >
                                Easy
                            </TwButton>
                            <TwButton
                                className={`${userInputs !== null && Number(userInputs[props.idx]) === 1 ? (activeCatagoryStyle) : (null)}`}
                                onClick={() => {
                                    props.updateSelection(props.idx, 1);
                                    dispatch(setFlashCardUserInputs({ item_index: props.idx, input_value: 1, is_general_tutor: props.is_general_tutor }));
                                }}
                                disabled={props.final_marking}
                            >
                                Medium
                            </TwButton>
                            <TwButton
                                className={`${userInputs !== null && Number(userInputs[props.idx]) === 2 ? (activeCatagoryStyle) : (null)}`}
                                onClick={() => {
                                    props.updateSelection(props.idx, 2);
                                    dispatch(setFlashCardUserInputs({ item_index: props.idx, input_value: 2, is_general_tutor: props.is_general_tutor }));
                                }}
                                disabled={props.final_marking}
                            >
                                Hard
                            </TwButton>
                            <TwButton
                                className='ml-3 text-xl'
                                onClick={() => { props.setDisplay(props.display + 1); props.setShowAnswer(false) }}
                            >
                                <FontAwesomeIcon icon={faAnglesRight} />
                            </TwButton>
                        </div>
                        <div className={`m-auto text-center`}>
                            <TwButton
                                onClick={() => { props.setShowAnswer(props.showAnswer === false) }}
                            >
                                Show/Hide
                            </TwButton>
                        </div>
                        <Markdown
                            className='
                            mb-8
                            text-lg
                            font-bold
                            tracking-tight
                            text-slate-900
                            dark:text-white
                            mt-4
                            '
                            remarkPlugins={[remarkMath, remarkGfm]}
                            rehypePlugins={[rehypeKatex]}
                        >
                            {flashcard?.backside_answer}
                        </Markdown>
                    </>
                )}
            </div>
        </>
    )
}
export function TwFlashcardsUI(props: { is_general_tutor?: boolean, is_revision?: boolean }) {
    const user = useSelector((state: RootState) => state.user)
    const course = useSelector((state: RootState) => state.course)
    const dispatch = useDispatch<AppDispatch>();
    //
    let assessment: FlashCardsType | null = null
    let content: FlashCardsType['content'] | null = null
    let marking: FlashCardsType['content']['marking'] | null | undefined | string = undefined
    let user_input: FlashCardsType['content']['user_input'] | null | undefined = undefined
    let assessment_completed: boolean | undefined = undefined
    if (props.is_general_tutor) {
        if (course.generalTutorAssessment) {
            assessment = (course.generalTutorAssessment as FlashCardsType)
            content = assessment?.content
            marking = content?.marking
        }
    } else {
        if (course.courseAssessment) {
            assessment = (course.courseAssessment as FlashCardsType)
            content = assessment?.content
            marking = content?.marking
        }
    }
    if (props.is_revision) {
        if (assessment && assessment.revision_completed) {
            marking = content?.revision_marking
        } else {
            marking = null
        }
    }
    //
    const [display, setDisplay] = useState(1)
    const [showAnswer, setShowAnswer] = useState(false)
    if (props.is_revision) {
        user_input = assessment?.content?.user_revision_input ? assessment?.content?.user_revision_input : {}
        assessment_completed = assessment?.revision_completed
    } else {
        user_input = assessment?.content?.user_input ? assessment?.content?.user_input : {}
        assessment_completed = assessment?.completed
    }
    //
    let keys: string[] = []
    if (content && 'flashcards' in content && content?.flashcards) {
        // setting upper and lower bounds of scrolling
        keys = Object.keys(content?.flashcards).sort()
        if (display > keys.length && keys.length > 0) {
            setDisplay(keys.length)
        } else if (display < 1 && keys.length > 0) {
            setDisplay(1)
        }
    }
    //
    const updateSelection = (idx: number, choice_key: string) => {
        let copy_temp_selection = { ...user_input }
        copy_temp_selection[idx] = choice_key
        if (assessment) {
            dispatch(setAssessmentUserInputs({ assessment_hid: assessment.hid, input_value: copy_temp_selection, is_general_tutor: props.is_general_tutor, is_revision: props.is_revision }))
        }
        if (
            assessment &&
            content && 'flashcards' in content && content?.flashcards &&
            Object.keys(copy_temp_selection).length > 0 &&
            Object.keys(copy_temp_selection).length === Object.keys(content?.flashcards).length &&
            !course.loading.streaming &&
            user.accessToken
        ) {
            dispatch(courseSaveUserInput({
                // you removed code that update the marking, dont forget
                // to include that again the redux way!
                // just create a marking entry on the dictionary
                body_data: {
                    assessment_hid: assessment.hid,
                    is_general_tutor: props.is_general_tutor,
                    is_revision: props.is_revision,
                    user_input: copy_temp_selection,
                },
                accessToken: user.accessToken,
            }))
        }
    }
    if (!assessment) {
        return null
    }
    return (
        <>
            <h1 className='m-auto text-center mb-8'>
                <FontAwesomeIcon icon={faGripLinesVertical} className='text-yellow-300 mr-1' />
                FlashCards
                <FontAwesomeIcon icon={faBoltLightning} className='text-yellow-300 ml-1' />
                {typeof marking === 'number' && assessment_completed ? (
                    <> <br /><span className='text-xl'>Score: {`${marking}%`}</span></>
                ) : (null)}
            </h1>
            <div>
                {keys.length === 0 && course.loading.streaming ? (
                    <div className='flex justify-center'>
                        <TwPulsing />
                    </div>
                ) : (null)}
                {keys.map(idx => (
                    <TwFlashcardItem
                        key={`flashCard_${idx}`}
                        idx={Number(idx)}
                        is_general_tutor={props.is_general_tutor}
                        is_revision={props.is_revision}
                        keys={keys}
                        display={display}
                        setDisplay={setDisplay}
                        updateSelection={updateSelection}
                        showAnswer={showAnswer}
                        setShowAnswer={setShowAnswer}
                        final_marking={typeof marking === 'number' && assessment.revision_completed ? true : false}
                    />
                ))}
                {!course.loading.streaming && !props.is_revision ? (
                    <div className='flex justify-center m-auto mt-4'>
                        {'flashcards' in assessment.content ? (
                            <TwButton
                                onClick={() => {
                                    dispatch(setRequestAIAssessmentResponse({
                                        activation: true,
                                        assessment_hid: assessment.hid,
                                        is_general_tutor: props.is_general_tutor,
                                        assessment_type: 'FlashCards'
                                    }))
                                }}
                                className="flex p-2 m-auto"
                            >
                                <FontAwesomeIcon icon={faArrowsRotate} />
                            </TwButton>
                        ) : (
                            <TwButton
                                onClick={() => {
                                    dispatch(setRequestAIAssessmentResponse({
                                        activation: true,
                                        assessment_hid: assessment.hid,
                                        is_general_tutor: props.is_general_tutor,
                                        assessment_type: 'FlashCards'
                                    }))
                                }}
                                className="flex p-4 font-bold mr-2 justify-center items-center h-full p-2 z-40 mt-4">
                                Start
                            </TwButton>
                        )}
                    </div>
                ) : (null)}
            </div>
        </>
    )
}
export function TwQuizUI(props: { is_general_tutor?: boolean, is_revision?: boolean }) {
    const user = useSelector((state: RootState) => state.user)
    const course = useSelector((state: RootState) => state.course)
    const dispatch = useDispatch<AppDispatch>();
    //
    let assessment: QuizType | null = null
    let content: QuizType['content'] | null = null
    let marking: QuizType['content']['marking'] | string | null = null
    let userInputs: QuizType['content']['user_input'] | undefined = undefined
    if (props.is_general_tutor) {
        if (course.generalTutorAssessment) {
            assessment = (course.generalTutorAssessment as QuizType)
            content = assessment?.content
            marking = content?.marking
            userInputs = content?.user_input ? assessment?.content?.user_input : {}
        }
    } else {
        if (course.courseAssessment) {
            assessment = (course.courseAssessment as QuizType)
            content = assessment?.content
            marking = content?.marking
            userInputs = content?.user_input ? assessment?.content?.user_input : {}
        }
    }
    if (props.is_revision) {
        if (assessment && assessment.revision_completed) {
            marking = content?.revision_marking
        } else {
            marking = null
        }
    }
    if (props.is_revision) {
        userInputs = assessment?.content?.user_revision_input ? assessment?.content?.user_revision_input : {}
    } else {
        userInputs = assessment?.content?.user_input ? assessment?.content?.user_input : {}
    }
    //
    let keys: number[] = []
    if (content && 'quiz' in content && content?.quiz) {
        keys = Object.keys(content.quiz).sort().map(Number)
    }
    //
    const updateSelection = (idx: number, choice_key: string,) => {
        var copy_temp_selection = { ...userInputs }
        copy_temp_selection[idx] = choice_key
        if (assessment) {
            dispatch(setAssessmentUserInputs({ assessment_hid: assessment.hid, input_value: copy_temp_selection, is_general_tutor: props.is_general_tutor, is_revision: props.is_revision }))
        }
        if (
            assessment &&
            content && 'quiz' in content &&
            Object.keys(copy_temp_selection).length > 0 &&
            Object.keys(copy_temp_selection).length === Object.keys(content?.quiz).length &&
            !course.loading.streaming &&
            user.accessToken
        ) {
            dispatch(courseSaveUserInput({
                // you removed code that update the marking, dont forget
                // to include that again the redux way!
                // just create a marking entry on the dictionary
                body_data: {
                    assessment_hid: assessment.hid,
                    is_general_tutor: props.is_general_tutor,
                    is_revision: props.is_revision,
                    user_input: copy_temp_selection,
                },
                accessToken: user.accessToken,
            }))
        }
    }
    if (!assessment || !content) {
        return null
    }
    return (
        <>
            <h1 className='m-auto text-center'>
                <FontAwesomeIcon icon={faGripLinesVertical} className='text-sky-500' /> Multiple Choice Quiz <FontAwesomeIcon icon={faClipboardQuestion} className='text-sky-500' />
                {marking !== null && marking && typeof marking === 'object' ? (
                    <>
                        <br />
                        <span className='text-xl'>Score:
                            {
                                0 in Object.keys(marking) ? `${marking[0]}%` : `-%`
                            }
                        </span>
                    </>
                ) : (null)}
            </h1>
            <Markdown remarkPlugins={[remarkMath, remarkGfm]} rehypePlugins={[rehypeKatex]}>{'quiz_introduction' in content ? content?.quiz_introduction : ''}</Markdown>
            {course.loading.streaming ? (
                <div className='flex justify-center'>
                    <TwPulsing />
                </div>
            ) : (null)}
            {keys.map(idx => (
                <div className='mb-3' key={`quiz_question_${idx}`}>
                    <div className='text-base italic border-t-2 p-2 mt-2'>
                        <Markdown className='text-lg tracking-tight text-slate-900 dark:text-white inline-block' remarkPlugins={[remarkMath, remarkGfm]} rehypePlugins={[rehypeKatex]}>
                            {`Question ${idx}: ` + ('quiz' in content ? content?.quiz[Number(idx)]?.question : '-')}
                        </Markdown>
                        <div className='ml-4'>
                            {'quiz' in content && content?.quiz[idx]?.choices ? (
                                Object.keys(content?.quiz[idx]?.choices).sort().map(choice_key => (
                                    <div className="mb-[0.125rem] block min-h-[1.5rem] pl-[1.5rem]" key={`multiple_choice_${idx}_${choice_key}`}>
                                        <input
                                            className={`relative float-left -ml-[1.5rem] mr-1 mt-0.5 h-5 w-5 appearance-none rounded-full border-2 border-solid border-neutral-300 before:pointer-events-none before:absolute before:h-4 before:w-4 before:scale-0 before:rounded-full before:bg-transparent before:opacity-0 before:shadow-[0px_0px_0px_13px_transparent] before:content-[''] after:absolute after:z-[1] after:block after:h-4 after:w-4 after:rounded-full after:content-[''] checked:border-primary checked:before:opacity-[0.16] checked:after:absolute checked:after:left-1/2 checked:after:top-1/2 checked:after:h-[0.625rem] checked:after:w-[0.625rem] checked:after:rounded-full checked:after:border-primary checked:after:bg-primary checked:after:content-[''] checked:after:[transform:translate(-50%,-50%)] hover:cursor-pointer hover:before:opacity-[0.04] hover:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)] focus:shadow-none focus:outline-none focus:ring-0 focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)] focus:before:transition-[box-shadow_0.2s,transform_0.2s] checked:focus:border-primary checked:focus:before:scale-100 checked:focus:before:shadow-[0px_0px_0px_13px_#3b71ca] checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] dark:border-neutral-600 dark:checked:border-primary dark:checked:after:border-primary dark:checked:after:bg-primary dark:focus:before:shadow-[0px_0px_0px_13px_rgba(255,255,255,0.4)] dark:checked:focus:border-primary dark:checked:focus:before:shadow-[0px_0px_0px_13px_#3b71ca] text-blue-700`}
                                            type="radio"
                                            name={`flexRadioDefault_${idx}`}
                                            id={`radioDefault_quiz_${idx}_${choice_key}`}
                                            onChange={() => { if (!marking) { updateSelection(idx, choice_key) } }}
                                            checked={userInputs && marking && Object.keys(userInputs).length > 0 ? (userInputs[idx] === choice_key ? (true) : (false)) : (
                                                userInputs?.hasOwnProperty(idx) ? (
                                                    userInputs[idx] === choice_key
                                                ) : (
                                                    false
                                                )
                                            )}
                                        />
                                        <label
                                            className={`
									    mt-px inline-block pl-[0.15rem] hover:cursor-pointer
									    ${marking !== null && marking ? (marking[1][idx] === choice_key ? (
                                                    marking[1][idx] === marking[2][idx][0] ? ('text-green-700') : ('text-red-700')
                                                ) : (null)) : (null)}
									     `}
                                            htmlFor={`radioDefault_quiz_${idx}_${choice_key}`}
                                        >
                                            <Markdown className='inline-block !m-1' key={`quiz_question_${idx}_option_${choice_key}`} remarkPlugins={[remarkMath, remarkGfm]} rehypePlugins={[rehypeKatex]}>{`(${choice_key}): ` + content?.quiz[idx]?.choices[choice_key]}</Markdown>
                                        </label>
                                    </div>
                                ))
                            ) : (null)}
                        </div>
                        {marking !== null && marking ? (
                            <>
                                <Markdown
                                    remarkPlugins={[remarkMath, remarkGfm]}
                                    rehypePlugins={[rehypeKatex]}
                                >
                                    {`Answer: 
                                        ${'quiz' in content ? content?.quiz[idx]?.answer?.answer : ''}
                                        (${'quiz' in content ? content?.quiz[idx]?.answer?.correct_choice : ''})`
                                    }
                                </Markdown>
                            </>
                        ) : (null)}
                    </div>
                </div>
            ))}
            {!course.loading.streaming && !props.is_revision ? (
                <div className='flex justify-center m-auto'>
                    {'quiz' in assessment.content ? (
                        <TwButton
                            onClick={
                                () => {
                                    dispatch(setRequestAIAssessmentResponse({
                                        activation: true,
                                        assessment_hid: assessment.hid,
                                        is_general_tutor: props.is_general_tutor,
                                        assessment_type: 'Quiz'
                                    }))
                                }}
                            className="flex p-2 m-auto"
                        >
                            <FontAwesomeIcon icon={faArrowsRotate} />
                        </TwButton>
                    ) : (
                        <TwButton
                            onClick={
                                () => {
                                    dispatch(setRequestAIAssessmentResponse({
                                        activation: true,
                                        assessment_hid: assessment.hid,
                                        is_general_tutor: props.is_general_tutor,
                                        assessment_type: 'Quiz'
                                    }))
                                }}
                            className="flex p-4 font-bold mr-2 justify-center items-center h-full p-2 z-40 mt-4">
                            Start
                        </TwButton>
                    )}
                </div >
            ) : (null)
            }
        </>
    )
}
export function TwVocabularyUI(props: { is_general_tutor?: boolean, is_revision?: boolean }) {
    const user = useSelector((state: RootState) => state.user)
    const course = useSelector((state: RootState) => state.course)
    const dispatch = useDispatch<AppDispatch>();
    //
    let [assessment, setAssessment] = useState<VocabularyType | null>(null)
    let content: VocabularyType['content'] | null = assessment ? assessment?.content : null
    let user_input: VocabularyType['content']['user_input'] | undefined | string[][] = undefined
    let marking: VocabularyType['content']['marking'] | undefined | string = undefined
    let assessment_completed: boolean | undefined = undefined
    //
    //
    let final_vocabulary: { word: string, hint: string | undefined }[] = []
    if (content && 'vocabulary_description' in content && content?.vocabulary_description) {
        var target_words: string[] = 'vocabulary_words' in content.vocabulary_description ? String(content?.vocabulary_description?.vocabulary_words).split('|*|') : []
        var hints: string[] = 'vocabulary_hints' in content.vocabulary_description ? String(content?.vocabulary_description?.vocabulary_hints).split('|*|') : []
        target_words.forEach(
            (targe_word) => {
                final_vocabulary.push({ word: targe_word.trim(), hint: hints.shift()?.trim() })
            }
        )

    }
    //
    useEffect(() => {
        if (props.is_general_tutor) {
            if (course.generalTutorAssessment) {
                setAssessment(course.generalTutorAssessment as VocabularyType)
            }
        } else {
            if (course.courseAssessment) {
                setAssessment(course.courseAssessment as VocabularyType)
            }
        }
    }, [course.generalTutorAssessment, course.courseAssessment, props.is_general_tutor])
    //
    if (props.is_revision) {
        if (assessment && assessment.revision_completed) {
            marking = content?.revision_marking
        } else {
            marking = undefined
        }
    } else {
        marking = content?.marking
    }
    if (props.is_revision) {
        user_input = assessment?.content?.user_revision_input ? assessment?.content?.user_revision_input : []
        assessment_completed = assessment?.revision_completed
    } else {
        user_input = assessment?.content?.user_input ? assessment?.content?.user_input : []
        assessment_completed = assessment?.completed
    }
    if (!user_input && final_vocabulary.length > 0) {
        user_input = final_vocabulary.map(() => '')
    }
    var textInput = user_input && user_input.length > 0 ? user_input : undefined


    const saveInput = (
        e: React.KeyboardEvent<HTMLDivElement> | React.FocusEvent<HTMLDivElement, Element>,
        question_index: number,
        force: boolean
    ) => {
        let user_text = (e.target as HTMLElement).innerText.trim()
        let user_text_length = (e.target as HTMLElement).innerText.length

        let temp_obj = [...user_input as string[]]
        temp_obj[question_index] = user_text

        if (user_text_length > 0) {
            if (
                assessment &&
                'hid' in assessment &&
                user.accessToken && force
            ) {
                dispatch(setAssessmentUserInputs({ input_value: temp_obj, assessment_hid: assessment?.hid, is_general_tutor: props.is_general_tutor, is_revision: props.is_revision }))
                dispatch(courseSaveUserInput(
                    {
                        body_data: {
                            assessment_hid: assessment.hid,
                            is_general_tutor: props.is_general_tutor,
                            is_revision: props.is_revision,
                            user_input: temp_obj
                        },
                        accessToken: user.accessToken,
                    }
                ))
            }
        }
    }
    if (!assessment || !content) {
        return null
    }
    const seededRandom = (seed: any) => {
        let m = 2 ** 31 - 1;
        let a = 48271;
        let s = seed % m;
        return () => {
            s = (a * s) % m;
            return s / m;
        };
    };
    const generateHint = (answer: any) => {
        const answerLength = answer?.length;
        const hintArray = Array(answerLength).fill('_');
        const hintCount = Math.max(2, Math.floor(answerLength / 4)); // Show a few characters, at least 2
        const random = seededRandom(123);

        for (let i = 0; i < hintCount; i++) {
            const randomIndex = Math.floor(random() * answerLength);
            hintArray[randomIndex] = answer[randomIndex];
        }

        return hintArray.join('');
    };
    return (
        <>
            <h1 className='m-auto text-center'>
                <FontAwesomeIcon icon={faGripLinesVertical} className='text-green-600' /> Vocabulary <FontAwesomeIcon icon={faSpellCheck} className='text-green-600' />
                {marking !== undefined && assessment_completed ? (
                    <> <br /><span className='text-xl'>Score: {`${marking}%`}</span></>
                ) : (null)}
            </h1>
            {course.loading.streaming ? (
                <div className='flex justify-center'>
                    <TwPulsing />
                </div>
            ) : (null)}
            <div className="text-lg font-bold tracking-tight text-slate-900 dark:text-white">
                {
                    content && 'vocabulary_description' in content && content.vocabulary_description && 'vocabulary_words' in content.vocabulary_description ? (
                        final_vocabulary.map((item: any, index: any) => (
                            <div
                                key={`vocabulary_${index}`}
                                className='border-t-2 p-2 mt-2'
                            >
                                <Markdown
                                    className='
                                        mb-2
                                        text-lg
                                        font-bold
                                        tracking-tight
                                        text-slate-900
                                        dark:text-white
                                        mt-1
                                    '
                                    remarkPlugins={[remarkMath, remarkGfm]}
                                    rehypePlugins={[rehypeKatex]}
                                >
                                    {`${String(index + 1)} ) ${item.hint}`}
                                </Markdown>

                                <div
                                    key={`vocabulary_input_${index}`}
                                    className='mb-2'
                                >
                                    <div className='text-xs'>
                                        Answer: {
                                            assessment_completed ? <span className='underline'>{item.word}</span> :
                                                generateHint(item.word)
                                        } {item.word?.length} letter(s) {item.word?.split(' ').length} word(s):
                                    </div>
                                    <div
                                        className={`
                                                    w-full ml-1 resize-none border-l-2 border-slate-950 dark:border-slate-50
                                                    p-2 bg-transparent p-0 focus:ring-0 focus-visible:ring-0 outline-none
                                                    max-h-[50px] h-auto whitespace-pre-line cloze_user_input_div
                                                    `}
                                        data-placeholder=""
                                        contentEditable={!assessment_completed ? true : false}
                                        suppressContentEditableWarning={true}
                                        onBlur={(e) => saveInput(e, index, true)}
                                        onKeyUp={(e) => saveInput(e, index, false)}
                                        id={`vocabulary_user_input_${index}`}
                                    >
                                        {
                                            textInput &&
                                                textInput.length >= index + 1 &&
                                                textInput[index]
                                                ? textInput[index] : '...'
                                        }
                                    </div>
                                </div>
                                <br />
                            </div>
                        ))
                    ) : ('')
                }
            </div>
            {!course.loading.streaming && !props.is_revision ? (
                <div className='flex justify-center m-auto'>
                    {'vocabulary_description' in assessment.content ? (
                        <TwButton
                            onClick={() => {
                                dispatch(setRequestAIAssessmentResponse({
                                    activation: true,
                                    assessment_hid: assessment.hid,
                                    is_general_tutor: props.is_general_tutor,
                                    assessment_type: 'Vocabulary'
                                }))
                                dispatch(setAssessmentUserInputs({ input_value: undefined, assessment_hid: assessment?.hid, is_general_tutor: props.is_general_tutor }))
                                dispatch(resetAssessment({ is_general_tutor: props.is_general_tutor }))
                            }}
                            className="flex p-2 m-auto"
                        >
                            <FontAwesomeIcon icon={faArrowsRotate} />
                        </TwButton>
                    ) : (
                        <TwButton
                            onClick={() => {
                                dispatch(setRequestAIAssessmentResponse({
                                    activation: true,
                                    assessment_hid: assessment.hid,
                                    is_general_tutor: props.is_general_tutor,
                                    assessment_type: 'Vocabulary'
                                }))
                                dispatch(setAssessmentUserInputs({ input_value: undefined, assessment_hid: assessment?.hid, is_general_tutor: props.is_general_tutor }))
                                dispatch(resetAssessment({ is_general_tutor: props.is_general_tutor }))
                            }}
                            className="flex p-4 font-bold mr-2 justify-center items-center h-full p-2 z-40 mt-4">
                            Start
                        </TwButton>
                    )}
                </div>
            ) : (null)}
        </>
    )
}
export function TwClozeCardsUI(props: { is_general_tutor?: boolean, is_revision?: boolean }) {
    const user = useSelector((state: RootState) => state.user)
    const course = useSelector((state: RootState) => state.course)
    const dispatch = useDispatch<AppDispatch>();
    //
    let [assessment, setAssessment] = useState<ClozeCardsType | null>(null)
    let content: ClozeCardsType['content'] | null = assessment ? assessment?.content : null
    let user_input: ClozeCardsType['content']['user_input'] | undefined | string[][] = undefined
    let marking: ClozeCardsType['content']['marking'] | undefined | string = undefined
    let assessment_completed: boolean | undefined = undefined

    //
    let final_clozecards: { question: string, answers: (string | undefined)[] }[] = []
    if (content && 'clozecards_description' in content && content?.clozecards_description) {
        var answers_array: string[] = 'clozecards_answers' in content.clozecards_description ? String(content?.clozecards_description?.clozecards_answers).split('|*|') : []
        var questions_array: string[] = 'clozecards_questions' in content.clozecards_description ? String(content?.clozecards_description?.clozecards_questions).split('|*|') : []
        questions_array.forEach(
            (question) => {
                let number_of_clozes = question.split('$CLOZE$').length - 1
                let answers: (string | undefined)[] = []
                for (let i = 0; i < number_of_clozes; i++) {
                    answers.push(answers_array.shift()?.trim());
                }
                final_clozecards.push({ question: question.replace(/\$CLOZE\$/g, '___').trim(), answers: answers })
            }
        )

    }
    useEffect(() => {
        if (props.is_general_tutor) {
            if (course.generalTutorAssessment) {
                setAssessment(course.generalTutorAssessment as ClozeCardsType)
            }
        } else {
            if (course.courseAssessment) {
                setAssessment(course.courseAssessment as ClozeCardsType)
            }
        }
    }, [course.generalTutorAssessment, course.courseAssessment, props.is_general_tutor])

    if (props.is_revision) {
        if (assessment && assessment.revision_completed) {
            marking = content?.revision_marking
        } else {
            marking = undefined
        }
    } else {
        marking = content?.marking
    }
    if (props.is_revision) {
        user_input = assessment?.content?.user_revision_input ? assessment?.content?.user_revision_input : undefined
        assessment_completed = assessment?.revision_completed
    } else {
        user_input = assessment?.content?.user_input ? assessment?.content?.user_input : undefined
        assessment_completed = assessment?.completed
    }
    if (!user_input && final_clozecards.length > 0) {
        user_input = final_clozecards.map(item => new Array(item.answers.length).fill(""))
    }
    var textInput = user_input && user_input.length > 0 ? user_input : undefined
    const saveInput = (
        e: React.KeyboardEvent<HTMLDivElement> | React.FocusEvent<HTMLDivElement, Element>,
        question_index: number,
        answer_index: number,
        force: boolean
    ) => {
        let user_text = (e.target as HTMLElement).innerText.trim()
        let user_text_length = (e.target as HTMLElement).innerText.length

        let temp_obj = [...user_input as string[][]]
        temp_obj[question_index] = [...temp_obj[question_index]]
        temp_obj[question_index][answer_index] = user_text

        if (user_text_length > 0) {
            if (
                assessment &&
                'hid' in assessment &&
                user.accessToken && force
            ) {
                dispatch(setAssessmentUserInputs({ input_value: temp_obj, assessment_hid: assessment?.hid, is_general_tutor: props.is_general_tutor, is_revision: props.is_revision }))
                dispatch(courseSaveUserInput(
                    {
                        body_data: {
                            assessment_hid: assessment.hid,
                            is_general_tutor: props.is_general_tutor,
                            is_revision: props.is_revision,
                            user_input: temp_obj
                        },
                        accessToken: user.accessToken,
                    }
                ))
            }
        }
    }
    const seededRandom = (seed: any) => {
        let m = 2 ** 31 - 1;
        let a = 48271;
        let s = seed % m;
        return () => {
            s = (a * s) % m;
            return s / m;
        };
    };
    const generateHint = (answer: any) => {
        const answerLength = answer?.length;
        const hintArray = Array(answerLength).fill('_');
        const hintCount = Math.max(1, Math.floor(answerLength / 3)); // Show a few characters, at least 1
        const random = seededRandom(123);

        if (answerLength >= 3) {
            for (let i = 0; i < hintCount; i++) {
                const randomIndex = Math.floor(random() * answerLength);
                hintArray[randomIndex] = answer[randomIndex];
            }
        }

        return hintArray.join('');
    };
    if (!assessment || !content) {
        return null
    }
    return (
        <>
            <h1 className='m-auto text-center'>
                <FontAwesomeIcon icon={faGripLinesVertical} className='text-indigo-600' /> ClozeCards <FontAwesomeIcon icon={faSignature} className='text-indigo-600' />
                {marking !== undefined && assessment_completed ? (
                    <> <br /><span className='text-xl'>Score: {`${marking}%`}</span></>
                ) : (null)}
            </h1>
            <div>
                {course.loading.streaming ? (
                    <div className='flex justify-center mt-3'>
                        <TwPulsing />
                    </div>
                ) : (null)}
                <div className="text-lg font-bold tracking-tight text-slate-900 dark:text-white">
                    {
                        content && 'clozecards_description' in content && content.clozecards_description && 'clozecards_questions' in content.clozecards_description ? (
                            final_clozecards.map((item: any, index: any) => (
                                <div
                                    key={`clozcard_${index}`}
                                    className='border-t-2 p-2 mt-2'
                                >
                                    <Markdown
                                        className='
                                                    mb-2
                                                    text-lg
                                                    font-bold
                                                    tracking-tight
                                                    text-slate-900
                                                    dark:text-white
                                                    mt-1
                                                '
                                        remarkPlugins={[remarkMath, remarkGfm]}
                                        rehypePlugins={[rehypeKatex]}
                                    >
                                        {`${String(index + 1)} ) ${item.question}`}
                                    </Markdown>

                                    {item.answers.map((answer_item: string, answer_index: number) => (
                                        <div
                                            key={`clozecard_${index}_answer_${answer_index}`}
                                            className='mb-2'
                                        >
                                            <div className='text-xs'>
                                                Answer # {answer_index + 1}: {
                                                    assessment_completed ? <span className='underline'>{answer_item}</span> :
                                                        generateHint(answer_item)
                                                } {answer_item?.length} letter(s) {answer_item?.split(' ').length} word(s):
                                            </div>
                                            <div
                                                className={`
                                                    w-full ml-1 resize-none border-l-2 border-slate-950 dark:border-slate-50
                                                    p-2 bg-transparent p-0 focus:ring-0 focus-visible:ring-0 outline-none
                                                    max-h-[50px] h-auto whitespace-pre-line cloze_user_input_div
                                                    `}
                                                data-placeholder=""
                                                contentEditable={!assessment_completed ? true : false}
                                                suppressContentEditableWarning={true}
                                                onBlur={(e) => saveInput(e, index, answer_index, true)}
                                                onKeyUp={(e) => saveInput(e, index, answer_index, false)}
                                                id={`cloze_user_input_${index}_${answer_index}`}
                                            >
                                                {
                                                    textInput &&
                                                        textInput.length >= index + 1 &&
                                                        textInput[index] &&
                                                        textInput[index].length >= answer_index + 1 &&
                                                        textInput[index][answer_index]
                                                        ? textInput[index][answer_index] : '...'
                                                }
                                            </div>
                                        </div>
                                    ))}

                                    <br />
                                </div>
                            ))
                        ) : ('')
                    }
                </div>
                {!course.loading.streaming && !props.is_revision ? (
                    <div className='flex justify-center m-auto'>
                        {'clozecards_description' in assessment.content ? (
                            <TwButton
                                onClick={() => {
                                    dispatch(setRequestAIAssessmentResponse({
                                        activation: true,
                                        assessment_hid: assessment.hid,
                                        is_general_tutor: props.is_general_tutor,
                                        assessment_type: 'ClozeCards'
                                    }))
                                    dispatch(setAssessmentUserInputs({ input_value: undefined, assessment_hid: assessment?.hid, is_general_tutor: props.is_general_tutor }))
                                    dispatch(resetAssessment({ is_general_tutor: props.is_general_tutor }))
                                }}
                                className="flex p-2 m-auto"
                            >
                                <FontAwesomeIcon icon={faArrowsRotate} />
                            </TwButton>
                        ) : (
                            <TwButton
                                onClick={() => {
                                    dispatch(setRequestAIAssessmentResponse({
                                        activation: true,
                                        assessment_hid: assessment.hid,
                                        is_general_tutor: props.is_general_tutor,
                                        assessment_type: 'ClozeCards'
                                    }))
                                    dispatch(setAssessmentUserInputs({ input_value: undefined, assessment_hid: assessment?.hid, is_general_tutor: props.is_general_tutor }))
                                    dispatch(resetAssessment({ is_general_tutor: props.is_general_tutor }))
                                }}
                                className="flex p-4 font-bold mr-2 justify-center items-center h-full p-2 z-40 mt-4">
                                Start
                            </TwButton>
                        )}
                    </div>
                ) : (null)}
            </div>
        </>
    )
}
export function TwChatCardAI(props: {
    id?: string,
    is_head: boolean,
    thread_index: number,
    pair_index?: number,
    newThread?: boolean,
    separation_style?: string,
}) {
    const course = useSelector((state: RootState) => state.course)
    const dispatch = useDispatch<AppDispatch>();
    //
    let is_head = props?.is_head
    let threadIndex = props?.thread_index
    let pair_index = props?.pair_index
    // 
    let chatThread = null
    if ('chat_threads' in course.courseLesson) {
        chatThread = course.courseLesson.chat_threads[threadIndex]
    }
    let chatItem = null
    //
    if (is_head && chatThread) {
        chatItem = chatThread[0]
    } else if (typeof pair_index === 'number' && chatThread) {
        chatItem = chatThread[1][pair_index][1]
    }
    if (!chatThread || !chatItem) {
        return null
    }
    return (
        <>
            <div
                className={`
    			overflow-scroll
    			flex flex-col pl-1 pr-1 py-1
    			transform transition-transform duration-250
    			${props.separation_style}
    			`}
                id={props.id}
            >
                <div className='flex flex-col items-start h-full mb-4'>
                    <div className="w-full">
                        <div className="flex flex-col ml-6 mr-5 md:ml-12 md:mr-10">
                            <div className="flex items-center h-full m-auto w-full justify-center">
                                <div className='text-base max-w-prose prose prose-slate prose-sm dark:prose-invert w-full'>
                                    <div className="flex flex-row w-full">
                                        <div className="mr-1">
                                            <img
                                                src="/ai_logo.jpeg"
                                                alt="CourseImage"
                                                className="w-7 h-7 ml-1 mt-1 aspect-auto object-cover rounded-full"
                                            />
                                        </div>
                                        <div className="flex w-full p-1 font-bold">
                                            Coursely {is_head ? (
                                                <>
                                                    <br />
                                                    {'point_titles' in course.courseLesson ? course.courseLesson.point_titles[threadIndex] : 0}
                                                </>
                                            ) : (null)}
                                        </div>

                                    </div>
                                    {(chatItem.response_type === 'point' ? (
                                        <>
                                            <TwPointUI
                                                thread_index={threadIndex}
                                                pair_index={pair_index}
                                            />
                                        </>
                                    ) : (null))}
                                    {(chatItem.response_type === 'normal' ? (
                                        <>
                                            <div className='text-sm max-w-prose prose prose-slate prose-sm dark:prose-invert'>
                                                <TwNormalPromptUI
                                                    thread_index={threadIndex}
                                                    pair_index={pair_index}
                                                />
                                            </div>
                                        </>
                                    ) : (null))}
                                    {
                                        (
                                            (
                                                'hid' in course.courseLesson &&
                                                course?.AIRequestBody?.lesson_hid === course.courseLesson.hid &&
                                                course?.AIRequestBody?.thread_index === threadIndex &&
                                                course?.AIRequestBody?.pair_index === pair_index) ||
                                            is_head === true
                                        ) &&
                                            (
                                                course.loading.courseGetAIRequestTicket ||
                                                course.loading.streaming
                                            ) ? (
                                            <TwPulsing className='float-right' />
                                        ) : (null)
                                    }
                                    <div className=''>
                                        {(is_head ? (
                                            !props.newThread ? (
                                                <div className='flex justify-between flex-row mb-4'>
                                                    {!props.newThread ? (
                                                        !course.loading.courseGetAIRequestTicket && !course.loading.streaming ? (
                                                            <>
                                                                <TwButton
                                                                    onClick={() => {
                                                                        dispatch(setRequestAIResponse({
                                                                            activation: true,
                                                                            courseId: course.courseId,
                                                                            lessonNumber: course.lessonNumber,
                                                                            threadIndex: threadIndex,
                                                                            is_head: is_head,
                                                                        }))
                                                                    }}
                                                                    className="flex p-2 m-auto"
                                                                >
                                                                    <FontAwesomeIcon icon={faArrowsRotate} />
                                                                </TwButton>
                                                            </>
                                                        ) : (null)
                                                    ) : (null)} <br />
                                                </div>
                                            ) : (
                                                !course.loading.courseGetAIRequestTicket && !course.loading.streaming ? (
                                                    <div className="flex flex-row mt-10 justify-center">
                                                        <TwButton
                                                            onClick={() => {
                                                                dispatch(setRequestAIResponse({
                                                                    activation: true,
                                                                    courseId: course.courseId,
                                                                    lessonNumber: course.lessonNumber,
                                                                    threadIndex: threadIndex,
                                                                    is_head: is_head,
                                                                }))
                                                            }}
                                                            className="flex p-4 font-bold mr-2 justify-center items-center h-full p-2 z-40">
                                                            Start
                                                        </TwButton>
                                                    </div>
                                                ) : (null)
                                            )
                                        ) : (
                                            null
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </>
    )
}
export function TwGeneralTutorChatCardAI(props: {
    id?: string,
    is_head: boolean,
    thread_index: number,
    pair_index?: number,
    newThread?: boolean,
    separation_style?: string,
}) {
    const course = useSelector((state: RootState) => state.course)
    const dispatch = useDispatch<AppDispatch>();
    //
    let is_head = props?.is_head
    let threadIndex = props?.thread_index
    let pair_index = props?.pair_index
    // 
    let chatThread = null
    if ('chat_threads' in course.generalTutorLesson) {
        chatThread = course.generalTutorLesson.chat_threads[threadIndex]
    }
    let chatItem = null
    //
    if (is_head && chatThread) {
        chatItem = chatThread[0]
    } else if (typeof pair_index === 'number' && chatThread) {
        chatItem = chatThread[1][pair_index][1]
    }
    if (!chatThread || !chatItem) {
        return null
    }
    return (
        <>
            <div
                className={`
    			overflow-scroll
    			flex flex-col pl-1 pr-1 py-1
    			transform transition-transform duration-250
    			${props.separation_style}
    			`}
                id={props.id}
            >
                <div className='flex flex-col items-start h-full mb-4'>
                    <div className="w-full">
                        <div className="flex flex-col ml-6 mr-5 md:ml-12 md:mr-10">
                            <div className="flex items-center h-full m-auto w-full justify-center">
                                <div className='text-base max-w-prose prose prose-slate prose-sm dark:prose-invert w-full'>
                                    <div className="flex flex-row w-full">
                                        <div className="mr-1">
                                            <img
                                                src="/ai_logo.jpeg"
                                                alt="CourseImage"
                                                className="w-7 h-7 ml-1 mt-1 aspect-auto object-cover rounded-full"
                                            />
                                        </div>
                                        <div className="flex w-full p-1 font-bold">
                                            Coursely {is_head ? (
                                                <>
                                                    <br />
                                                    {
                                                        'lessonpoints' in course.generalTutorLesson && course.generalTutorLesson.lessonpoints ?
                                                            course.generalTutorLesson.lessonpoints[threadIndex] : 0
                                                    }
                                                </>
                                            ) : (null)}
                                        </div>

                                    </div>
                                    {(chatItem.response_type === 'point' ? (
                                        <>
                                            <TwPointUI
                                                thread_index={threadIndex}
                                                pair_index={pair_index}
                                                is_general_tutor={true}
                                            />
                                        </>
                                    ) : (null))}
                                    {(chatItem.response_type === 'normal' ? (
                                        <>
                                            <div className='text-sm max-w-prose prose prose-slate prose-sm dark:prose-invert'>
                                                <TwNormalPromptUI
                                                    thread_index={threadIndex}
                                                    pair_index={pair_index}
                                                    is_general_tutor={true}
                                                />
                                            </div>
                                        </>
                                    ) : (null))}
                                    {
                                        (
                                            (
                                                'hid' in course.generalTutorLesson &&
                                                course?.AIRequestBody?.is_general_tutor &&
                                                course?.AIRequestBody?.lesson_hid === course.generalTutorLesson.hid &&
                                                course?.AIRequestBody?.thread_index === threadIndex &&
                                                course?.AIRequestBody?.pair_index === pair_index) ||
                                            is_head === true
                                        ) &&
                                            (
                                                course.loading.courseGetAIRequestTicket ||
                                                course.loading.streaming
                                            ) ? (
                                            <TwPulsing className='float-right' />
                                        ) : (null)
                                    }
                                    <div className=''>
                                        {(is_head ? (
                                            !props.newThread ? (
                                                <div className='flex justify-between flex-row mb-4'>
                                                    {!props.newThread ? (
                                                        !course.loading.courseGetAIRequestTicket && !course.loading.streaming ? (
                                                            <>
                                                                <TwButton
                                                                    onClick={() => {
                                                                        dispatch(setRequestAIResponse({
                                                                            activation: true,
                                                                            generalTutorLessonHid: 'hid' in course.generalTutorLesson ? course.generalTutorLesson.hid : '',
                                                                            threadIndex: threadIndex,
                                                                            is_head: is_head,
                                                                        }))
                                                                    }}
                                                                    className="flex p-2 m-auto"
                                                                >
                                                                    <FontAwesomeIcon icon={faArrowsRotate} />
                                                                </TwButton>
                                                            </>
                                                        ) : (null)
                                                    ) : (null)} <br />
                                                </div>
                                            ) : (
                                                !course.loading.courseGetAIRequestTicket &&
                                                    !course.loading.streaming ? (
                                                    <div className="flex flex-row mt-10 justify-center">
                                                        <TwButton
                                                            onClick={() => {
                                                                dispatch(setRequestAIResponse({
                                                                    activation: true,
                                                                    generalTutorLessonHid: 'hid' in course.generalTutorLesson ? course.generalTutorLesson.hid : '',
                                                                    threadIndex: threadIndex,
                                                                    is_head: is_head,
                                                                }))
                                                            }}
                                                            className="flex p-4 font-bold mr-2 justify-center items-center h-full p-2 z-40">
                                                            Start
                                                        </TwButton>
                                                    </div>
                                                ) : (null)
                                            )
                                        ) : (
                                            null
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </>
    )
}
export function TwChatCardUser(props: {
    thread_index: number | string,
    pair_index: number | string,
    separation_style?: string,
    ukey: string,
}) {
    const course = useSelector((state: RootState) => state.course)
    const dispatch = useDispatch<AppDispatch>();
    //
    let chatThread = null
    if ('chat_threads' in course.courseLesson) {
        chatThread = course.courseLesson.chat_threads[Number(props.thread_index)]
    }
    const chatPair = chatThread ? chatThread[1][Number(props.pair_index)] : null
    let user_chatitem = chatPair ? chatPair[0] : null
    //
    const [editMode, setEditMode] = useState(false)
    const [updatedPrompt, setUpdatedPrompt] = useState(false)
    //
    useEffect(() => {
        if (updatedPrompt) {
            dispatch(setRequestAIResponse({
                activation: true,
                courseId: course.courseId,
                lessonNumber: course.lessonNumber,
                threadIndex: Number(props.thread_index),
                pairIndex: Number(props.pair_index),
            }));
            setUpdatedPrompt(false)
        }
    }, [dispatch, updatedPrompt, course.courseId, course.lessonNumber, props.pair_index, props.thread_index])


    if (!chatThread || !chatPair || !user_chatitem) {
        return null
    }
    return (
        <div
            className={`
				overflow-scroll
				flex flex-col pl-1 pr-1 py-1
				transform transition-transform duration-250
				${props.separation_style}
				`}
        >
            <div className='flex flex-col ml-6 mr-5 md:ml-12 md:mr-10'>
                <div className='flex items-center h-full m-auto w-full justify-center' >
                    <div className='text-base max-w-prose prose prose-slate prose-sm dark:prose-invert w-full' >
                        <div className="flex flex-row">
                            <div className="mr-1">
                                <img
                                    src="/user_icon.jpeg"
                                    alt="UserIcon"
                                    className="w-7 h-7 ml-1 mt-1 aspect-auto object-cover rounded-full"
                                />
                            </div>
                            <div className="flex  p-1 font-bold">
                                You
                            </div>
                        </div>
                        <div className="w-full">
                            <div className="flex flex-col w-full">
                                {editMode ? (
                                    <>
                                        <div
                                            className="w-full m-0 resize-none border-l-2 p-2 bg-transparent focus:ring-0 focus-visible:ring-0 outline-none resize-none min-h-[150px] h-auto"
                                            data-placeholder=""
                                            contentEditable="true"
                                            suppressContentEditableWarning={true}
                                            key={`editable-${props.thread_index}-${props.pair_index}`}
                                            id={`editable-${props.thread_index}-${props.pair_index}`}
                                        >{user_chatitem.message}</div>
                                        <div className="flex flex-row mt-4 justify-center">
                                            <TwButton
                                                onClick={() => {
                                                    dispatch(updateChatItem(
                                                        {
                                                            user_input: document.getElementById(`editable-${props.thread_index}-${props.pair_index}`)?.innerText,
                                                            threadIndex: Number(props.thread_index),
                                                            pairIndex: Number(props.pair_index),
                                                        }
                                                    ));
                                                    setUpdatedPrompt(true);
                                                    setEditMode(false);
                                                }}
                                                className="m-auto flex p-4 font-bold">
                                                Submit
                                            </TwButton>
                                            <TwButton
                                                onClick={() => {
                                                    setEditMode(false);
                                                }}
                                                className="flex p-4 font-bold"
                                                key={`readOnly-${props.thread_index}-${props.pair_index}`}
                                            >
                                                cancel
                                            </TwButton>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <div className="flex h-full m-auto w-full" id={`readOnly-${props.thread_index}-${props.pair_index}`}>
                                            {user_chatitem.message}
                                        </div>
                                        {
                                            !course.loading.courseGetAIRequestTicket &&
                                                !course.loading.streaming
                                                ? (
                                                    <div className="flex flex-row justify-end">
                                                        <span onClick={() => setEditMode(true)} className="flex cursor-pointer p-4 font-bold justify-center items-center h-full p-2">
                                                            <FontAwesomeIcon icon={faPenToSquare} className='p-1' />
                                                        </span>
                                                    </div>
                                                ) : (
                                                    <div className="flex flex-row justify-end">
                                                        <span className="flex cursor-pointer p-4 font-bold justify-center items-center h-full p-2">
                                                            <FontAwesomeIcon icon={faBan} className='w-4 h-4' />
                                                        </span>
                                                    </div>
                                                )
                                        }
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export function TwGeneralTutorChatCardUser(props: {
    thread_index: number | string,
    pair_index: number | string,
    separation_style?: string,
    ukey: string,
}) {
    const course = useSelector((state: RootState) => state.course)
    const dispatch = useDispatch<AppDispatch>();
    //
    let chatThread = null
    if ('chat_threads' in course.generalTutorLesson) {
        chatThread = course.generalTutorLesson.chat_threads[Number(props.thread_index)]
    }
    const chatPair = chatThread ? chatThread[1][Number(props.pair_index)] : null
    let user_chatitem = chatPair ? chatPair[0] : null
    //
    const [editMode, setEditMode] = useState(false)
    const [updatedPrompts, setUpdatedPrompts] = useState(false)
    const actionInitiatedRef = useRef(false);
    useEffect(() => {
        if (updatedPrompts && 'hid' in course.generalTutorLesson && !actionInitiatedRef.current) {
            actionInitiatedRef.current = true;
            dispatch(setRequestAIResponse({
                activation: true,
                generalTutorLessonHid: course.generalTutorLesson.hid,
                threadIndex: Number(props.thread_index),
                pairIndex: Number(props.pair_index),
                is_head: false
            }));
            setUpdatedPrompts(false)
        } else if (!updatedPrompts) {
            actionInitiatedRef.current = false; // Reset ref when submittedInput is false again
        }
    }, [dispatch, updatedPrompts, course.generalTutorLesson, props.pair_index, props.thread_index])


    if (!chatThread || !chatPair || !user_chatitem) {
        return null
    }
    return (
        <div
            className={`
				overflow-scroll
				flex flex-col pl-1 pr-1 py-1
				transform transition-transform duration-250
				${props.separation_style}
				`}
        >
            <div className='flex flex-col ml-6 mr-5 md:ml-12 md:mr-10'>
                <div className='flex items-center h-full m-auto w-full justify-center' >
                    <div className='text-base max-w-prose prose prose-slate prose-sm dark:prose-invert w-full' >
                        <div className="flex flex-row">
                            <div className="mr-1">
                                <img
                                    src="/user_icon.jpeg"
                                    alt="UserIcon"
                                    className="w-7 h-7 ml-1 mt-1 aspect-auto object-cover rounded-full"
                                />
                            </div>
                            <div className="flex  p-1 font-bold">
                                You
                            </div>
                        </div>
                        <div className="w-full">
                            <div className="flex flex-col w-full">
                                {editMode ? (
                                    <>
                                        <div
                                            className="w-full m-0 resize-none border-l-2 p-2 bg-transparent focus:ring-0 focus-visible:ring-0 outline-none resize-none min-h-[150px] h-auto"
                                            data-placeholder=""
                                            contentEditable="true"
                                            suppressContentEditableWarning={true}
                                            key={`editable-${props.thread_index}-${props.pair_index}`}
                                            id={`editable-${props.thread_index}-${props.pair_index}`}
                                        >{user_chatitem.message}</div>
                                        <div className="flex flex-row mt-4 justify-center">
                                            <TwButton
                                                onClick={() => {
                                                    dispatch(updateGeneralTutorChatItem(
                                                        {
                                                            user_input: document.getElementById(`editable-${props.thread_index}-${props.pair_index}`)?.innerText,
                                                            threadIndex: Number(props.thread_index),
                                                            pairIndex: Number(props.pair_index),
                                                        }
                                                    ));
                                                    setEditMode(false);
                                                    setUpdatedPrompts(true);
                                                }}
                                                className="m-auto flex p-4 font-bold">
                                                Submit
                                            </TwButton>
                                            <TwButton
                                                onClick={() => {
                                                    setEditMode(false);
                                                }}
                                                className="flex p-4 font-bold"
                                                key={`readOnly-${props.thread_index}-${props.pair_index}`}
                                            >
                                                cancel
                                            </TwButton>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <div className="flex h-full m-auto w-full" id={`readOnly-${props.thread_index}-${props.pair_index}`}>
                                            {user_chatitem.message}
                                        </div>
                                        {
                                            !course.loading.courseGetAIRequestTicket &&
                                                !course.loading.streaming
                                                ? (
                                                    <div className="flex flex-row justify-end">
                                                        <span onClick={() => setEditMode(true)} className="flex cursor-pointer p-4 font-bold justify-center items-center h-full p-2">
                                                            <FontAwesomeIcon icon={faPenToSquare} className='p-1' />
                                                        </span>
                                                    </div>
                                                ) : (
                                                    <div className="flex flex-row justify-end">
                                                        <span className="flex cursor-pointer p-4 font-bold justify-center items-center h-full p-2">
                                                            <FontAwesomeIcon icon={faBan} className='w-4 h-4' />
                                                        </span>
                                                    </div>
                                                )
                                        }
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export function TwChatPair(props: {
    threadIndex: number | string,
    pairIndex: number | string,
    ukey: string
}) {
    const course = useSelector((state: RootState) => state.course)
    // Pair context and setfunctions
    let chatPair = null
    let assistant_chatitem = null
    let user_chatitem = null
    if ('chat_threads' in course.courseLesson) {
        chatPair = course.courseLesson.chat_threads[Number(props.threadIndex)][1][Number(props.pairIndex)]
        assistant_chatitem = chatPair[1]
        user_chatitem = chatPair[0]
    }
    if (!chatPair || !assistant_chatitem || !user_chatitem) {
        return null
    }
    return (
        <>
            {user_chatitem ? (
                <TwChatCardUser
                    thread_index={props.threadIndex}
                    pair_index={props.pairIndex}

                    key={`chatthread_${props.threadIndex}_item_${props.pairIndex}_student`}
                    ukey={`chatthread_${props.threadIndex}_item_${props.pairIndex}_student`}
                >
                </TwChatCardUser>
            ) : (null)}

            {assistant_chatitem ? (
                <TwChatCardAI
                    thread_index={Number(props.threadIndex)}
                    pair_index={Number(props.pairIndex)}

                    is_head={false}
                    key={`chatthread_${props.threadIndex}_item_${props.pairIndex}_assistant`}
                >
                </TwChatCardAI>
            ) : (null)}
        </>
    )
}
export function TwGeneralTutorChatPair(props: {
    threadIndex: number | string,
    pairIndex: number | string,
    ukey: string
}) {
    const course = useSelector((state: RootState) => state.course)
    // Pair context and setfunctions
    let chatPair = null
    let assistant_chatitem = null
    let user_chatitem = null
    if ('chat_threads' in course.generalTutorLesson) {
        chatPair = course.generalTutorLesson.chat_threads[Number(props.threadIndex)][1][Number(props.pairIndex)]
        assistant_chatitem = chatPair[1]
        user_chatitem = chatPair[0]
    }
    if (!chatPair || !assistant_chatitem || !user_chatitem) {
        return null
    }
    return (
        <>
            {user_chatitem ? (
                <TwGeneralTutorChatCardUser
                    thread_index={props.threadIndex}
                    pair_index={props.pairIndex}

                    key={`chatthread_${props.threadIndex}_item_${props.pairIndex}_student`}
                    ukey={`chatthread_${props.threadIndex}_item_${props.pairIndex}_student`}
                >
                </TwGeneralTutorChatCardUser>
            ) : (null)}

            {assistant_chatitem ? (
                <TwGeneralTutorChatCardAI
                    thread_index={Number(props.threadIndex)}
                    pair_index={Number(props.pairIndex)}

                    is_head={false}
                    key={`chatthread_${props.threadIndex}_item_${props.pairIndex}_assistant`}
                >
                </TwGeneralTutorChatCardAI>
            ) : (null)}
        </>
    )
}
export function TwChatThread(props: { threadIndex: number, newThread: boolean }) {
    const user = useSelector((state: RootState) => state.user)
    const course = useSelector((state: RootState) => state.course)
    const dispatch = useDispatch<AppDispatch>();
    //
    let threadIndex = props.threadIndex
    let chatThread = null
    if ('chat_threads' in course.courseLesson) {
        chatThread = course.courseLesson.chat_threads[threadIndex]
    }
    let newThread = props.newThread
    //
    let display = 'block'
    if (course.activeThread !== threadIndex) {
        display = 'hidden'
    }
    //
    let lesson = course.courseLesson
    //
    useEffect(() => {
        if (
            chatThread &&
            'hid' in lesson &&
            lesson.hid &&
            course?.requestAIResponse !== false &&
            typeof course?.requestAIResponse !== 'boolean' &&
            course?.requestAIResponse?.activation &&
            course.requestAIResponse.courseId === course.courseId &&
            course.requestAIResponse.lessonNumber === course.lessonNumber &&
            Number(course.requestAIResponse.threadIndex) === Number(threadIndex) &&
            user.accessToken
        ) {
            let request_body: CourseState['AIRequestBody'] = {
                thread: chatThread,
                lesson_hid: lesson.hid,
                thread_index: threadIndex,
                is_head: course.requestAIResponse.is_head,
                pair_index: course.requestAIResponse.pairIndex,
            }

            if (course.requestAIResponse.is_head) {
                let copyThread = chatThread
                copyThread = [
                    {
                        "whois": "Assistant",
                        "type": "response",
                        "response_type": "point",
                        "message": ''
                    }, []
                ]
                dispatch(setChatThread(
                    {
                        chatThread: [{ ...copyThread[0] }, []],
                        thread_index: threadIndex
                    }
                ))
                request_body = {
                    ...request_body,
                    thread: copyThread,
                }
            } else {
                if (
                    typeof course.requestAIResponse !== 'boolean' &&
                    'pairIndex' in course.requestAIResponse &&
                    typeof course.requestAIResponse.pairIndex !== 'object'
                ) {
                    let new_pair = chatThread[1][course.requestAIResponse.pairIndex]
                    new_pair = [new_pair[0], { ...new_pair[1], message: 'Replace me with use input.' }]
                    let sub_thread = [...chatThread[1]]
                    sub_thread[course.requestAIResponse.pairIndex] = new_pair
                    let temp_paird_thread: [
                        { [key: string]: string },
                        {
                            [key: number]: {
                                message: string,
                                request_type?: string,
                                response_type?: string,
                                type: string,
                                whois: string,
                            }
                        }[]
                    ] = [
                            { ...chatThread[0] },
                            [...sub_thread.slice(0, course.requestAIResponse.pairIndex + 1)]
                        ]
                    dispatch(setChatThread({
                        chatThread: temp_paird_thread,
                        threadIndex: threadIndex
                    }))
                    request_body = {
                        ...request_body,
                        thread: temp_paird_thread,
                    }
                }
            }
            dispatch(setRequestAIResponse(false))
            dispatch(courseGetAIRequestTicket(
                {
                    body_data: request_body,
                    accessToken: user.accessToken,
                }
            ))
        }
    }, [
        dispatch,
        course.requestAIResponse,
        threadIndex,
        chatThread,
        course.courseId,
        course.lessonNumber,
        lesson,
        user.accessToken
    ]
    )
    useEffect(() => {
        if (
            'hid' in course.courseLesson &&
            course.AIRequestTicket?.function_app_endpoint?.lesson_hid === course.courseLesson.hid &&
            course.AIRequestTicket?.function_app_endpoint?.thread_index === threadIndex
        ) {
            dispatch(runStream(
                {
                    requestTicket: course.AIRequestTicket,
                    request_body: course.AIRequestBody,
                }
            ))
        }
    }, [
        dispatch,
        course.AIRequestTicket,
        threadIndex,
        course.AIRequestBody,
        course.courseLesson
    ]
    )
    //
    if (!chatThread) {
        return null
    }
    return (
        <div className={`${display} mb-48 mt-12`}>
            <TwChatCardAI
                key={`chatthread_head_${props.threadIndex}`}
                id={`point-${props.threadIndex + 1}`}

                is_head={true}
                thread_index={threadIndex}

                newThread={newThread}

                separation_style="border-black dark:border-white"
            >
            </TwChatCardAI>
            {chatThread[1].map((_, idx: number) => (
                <TwChatPair
                    key={`chatpair_${threadIndex}_item_${idx}`}
                    ukey={`chatpair_${threadIndex}_item_${idx}`}

                    threadIndex={threadIndex}
                    pairIndex={idx}
                >
                </TwChatPair>
            ))}
        </div>
    )
}
export function TwGeneralTutorChatThread(props: { threadIndex: number, newThread: boolean }) {
    const user = useSelector((state: RootState) => state.user)
    const course = useSelector((state: RootState) => state.course)
    const dispatch = useDispatch<AppDispatch>();
    //
    let threadIndex = props.threadIndex
    let chatThread = null
    if ('chat_threads' in course.generalTutorLesson) {
        chatThread = course.generalTutorLesson.chat_threads[threadIndex]
    }
    let newThread = props.newThread
    //
    let display = 'block'
    if (course.activeGeneralTutorThread !== threadIndex) {
        display = 'hidden'
    }
    //
    let lesson = course.generalTutorLesson
    //
    useEffect(() => {
        if (
            chatThread &&
            'hid' in lesson &&
            lesson.hid &&
            course?.requestAIResponse !== false &&
            typeof course?.requestAIResponse !== 'boolean' &&
            course?.requestAIResponse?.activation &&
            course.requestAIResponse.generalTutorLessonHid === lesson.hid &&
            Number(course.requestAIResponse.threadIndex) === Number(threadIndex) &&
            user.accessToken
        ) {
            let request_body: CourseState['AIRequestBody'] = {
                thread: chatThread,
                lesson_hid: lesson.hid,
                thread_index: threadIndex,
                is_head: course.requestAIResponse.is_head,
                is_general_tutor: true,
                pair_index: course.requestAIResponse.pairIndex,
            }

            if (course.requestAIResponse.is_head) {
                let copyThread = chatThread
                copyThread = [
                    {
                        "whois": "Assistant",
                        "type": "response",
                        "response_type": "point",
                        "message": ''
                    }, []
                ]
                dispatch(setGeneralTutorLessonChatThread(
                    {
                        chatThread: [{ ...copyThread[0] }, []],
                        thread_index: threadIndex
                    }
                ))
                request_body = {
                    ...request_body,
                    thread: copyThread,
                }
            } else {
                if (
                    typeof course.requestAIResponse !== 'boolean' &&
                    'pairIndex' in course.requestAIResponse &&
                    typeof course.requestAIResponse.pairIndex !== 'object'
                ) {
                    let new_pair = chatThread[1][course.requestAIResponse.pairIndex]
                    new_pair = [new_pair[0], { ...new_pair[1], message: 'Replace me with use input.' }]
                    let sub_thread = [...chatThread[1]]
                    sub_thread[course.requestAIResponse.pairIndex] = new_pair
                    let temp_paird_thread: [
                        { [key: string]: string },
                        {
                            [key: number]: {
                                message: string,
                                request_type?: string,
                                response_type?: string,
                                type: string,
                                whois: string,
                            }
                        }[]
                    ] = [
                            { ...chatThread[0] },
                            [...sub_thread.slice(0, course.requestAIResponse.pairIndex + 1)]
                        ]
                    dispatch(setGeneralTutorLessonChatThread({
                        chatThread: temp_paird_thread,
                        threadIndex: threadIndex
                    }))
                    request_body = {
                        ...request_body,
                        thread: temp_paird_thread,
                    }
                }
            }
            dispatch(setRequestAIResponse(false))
            dispatch(courseGetAIRequestTicket(
                {
                    body_data: request_body,
                    accessToken: user.accessToken,
                }
            ))
        }
    }, [
        dispatch,
        course.requestAIResponse,
        threadIndex,
        chatThread,
        lesson,
        user.accessToken
    ]
    )
    useEffect(() => {
        if (
            'hid' in course.generalTutorLesson &&
            course.AIRequestTicket?.function_app_endpoint?.lesson_hid === course.generalTutorLesson.hid &&
            course.AIRequestTicket?.function_app_endpoint?.thread_index === threadIndex &&
            (
                course.AIRequestTicket?.function_call?.name === 'general_tutor_create_a_point' ||
                !course.AIRequestTicket?.function_call
            )
        ) {
            dispatch(runGeneralTutorStream(
                {
                    requestTicket: course.AIRequestTicket,
                    request_body: course.AIRequestBody,
                }
            ))
        }
    }, [
        dispatch,
        course.AIRequestTicket,
        threadIndex,
        course.AIRequestBody,
        course.generalTutorLesson
    ]
    )
    //
    if (!chatThread) {
        return null
    }
    return (
        <div className={`${display} mb-48 mt-12`}>
            <TwGeneralTutorChatCardAI
                key={`chatthread_head_${props.threadIndex}`}
                id={`point-${props.threadIndex + 1}`}

                is_head={true}
                thread_index={threadIndex}

                newThread={newThread}

                separation_style="border-black dark:border-white"
            >
            </TwGeneralTutorChatCardAI>
            {chatThread[1].map((_, idx: number) => (
                <TwGeneralTutorChatPair
                    key={`chatpair_${threadIndex}_item_${idx}`}
                    ukey={`chatpair_${threadIndex}_item_${idx}`}

                    threadIndex={threadIndex}
                    pairIndex={idx}
                >
                </TwGeneralTutorChatPair>
            ))}
        </div>
    )
}
export function TwAssessmentWrapper() {
    const user = useSelector((state: RootState) => state.user)
    const course = useSelector((state: RootState) => state.course)
    const dispatch = useDispatch<AppDispatch>();
    //
    let assessment = null
    if (
        course.courseAssessment
    ) {
        assessment = course.courseAssessment
    }
    //
    useEffect(() => {
        if (
            assessment &&
            typeof course.requestAIAssessmentResponse !== 'boolean' &&
            course.courseAssessment &&
            course.requestAIAssessmentResponse.activation &&
            'hid' in assessment &&
            course.requestAIAssessmentResponse.assessment_hid === assessment.hid &&
            !course.requestAIAssessmentResponse.is_general_tutor
        ) {
            let request_body: CourseState['AIRequestBody'] = {
                assessment_hid: course.requestAIAssessmentResponse.assessment_hid,
                assessment_type: course.requestAIAssessmentResponse.assessment_type,
                accessToken: user.accessToken ? user.accessToken : undefined,
            }
            if (
                'accessToken' in user &&
                user.accessToken
            ) {
                dispatch(courseGetAIAssessmentRequestTicket(
                    {
                        body_data: request_body,
                        accessToken: user.accessToken,
                    }
                ))
            }
        }
    }, [
        dispatch,
        course.requestAIAssessmentResponse,
        assessment,
        course.courseAssessment,
        user
    ]
    )
    useEffect(() => {
        let assessment = course.courseAssessment
        if (
            assessment &&
            'hid' in assessment &&
            course.AIRequestTicket?.function_app_endpoint?.assessment_hid === assessment.hid
        ) {
            dispatch(runAssessmentStream(
                {
                    requestTicket: course.AIRequestTicket,
                    request_body: course.AIRequestBody,
                }
            ))
        }
    }, [
        dispatch,
        course.AIRequestTicket,
        course.AIRequestBody,
        course.courseAssessment
    ]
    )
    //
    if (!assessment) {
        return null
    }
    return (
        <div className={`block mb-48 mt-1`}>
            {course.loading.courseGetAIAssessmentRequestTicket ? (
                <TwLoading className='w-6 h-6 mt-4' />
            ) : (
                <>
                    {
                        assessment.assessment_type === 'FlashCards' ? (
                            <div className='text-sm max-w-prose prose prose-slate prose-sm dark:prose-invert m-auto'>
                                <TwFlashcardsUI />
                            </div>
                        ) : (null)
                    }
                    {
                        assessment.assessment_type === 'Quiz' ? (
                            <div className='text-sm max-w-prose prose prose-slate prose-sm dark:prose-invert m-auto'>
                                <TwQuizUI />
                            </div>
                        ) : (null)
                    }
                    {
                        assessment.assessment_type === 'Vocabulary' ? (
                            <div className='text-sm max-w-prose prose prose-slate prose-sm dark:prose-invert m-auto'>
                                <TwVocabularyUI />
                            </div>
                        ) : (null)
                    }
                    {
                        assessment.assessment_type === 'ClozeCards' ? (
                            <div className='text-sm max-w-prose prose prose-slate prose-sm dark:prose-invert m-auto'>
                                <TwClozeCardsUI />
                            </div>
                        ) : (null)
                    }
                </>
            )}
        </div>
    )
}
export function TwRevisionAssessmentWrapper() {
    const course = useSelector((state: RootState) => state.course)
    //
    let assessment = null
    if (
        course.courseAssessment
    ) {
        assessment = course.courseAssessment
    }
    //
    if (!assessment) {
        return null
    }
    return (
        <div className={`block mb-48 mt-1`}>
            {course.loading.courseGetAIAssessmentRequestTicket ? (
                <TwLoading className='w-6 h-6 mt-4' />
            ) : (
                <>
                    {
                        assessment.assessment_type === 'FlashCards' ? (
                            <div className='text-sm max-w-prose prose prose-slate prose-sm dark:prose-invert m-auto'>
                                <TwFlashcardsUI is_revision={true} />
                            </div>
                        ) : (null)
                    }
                    {
                        assessment.assessment_type === 'Quiz' ? (
                            <div className='text-sm max-w-prose prose prose-slate prose-sm dark:prose-invert m-auto'>
                                <TwQuizUI is_revision={true} />
                            </div>
                        ) : (null)
                    }
                    {
                        assessment.assessment_type === 'Vocabulary' ? (
                            <div className='text-sm max-w-prose prose prose-slate prose-sm dark:prose-invert m-auto'>
                                <TwVocabularyUI is_revision={true} />
                            </div>
                        ) : (null)
                    }
                    {
                        assessment.assessment_type === 'ClozeCards' ? (
                            <div className='text-sm max-w-prose prose prose-slate prose-sm dark:prose-invert m-auto'>
                                <TwClozeCardsUI is_revision={true} />
                            </div>
                        ) : (null)
                    }
                </>
            )}
        </div>
    )
}
export function TwGeneralTutorAssessmentWrapper() {
    const user = useSelector((state: RootState) => state.user)
    const course = useSelector((state: RootState) => state.course)
    const dispatch = useDispatch<AppDispatch>();
    //
    let assessment = null
    if (
        course.generalTutorAssessment
    ) {
        assessment = course.generalTutorAssessment
    }
    //
    useEffect(() => {
        if (
            assessment &&
            typeof course.requestAIAssessmentResponse !== 'boolean' &&
            course.generalTutorAssessment &&
            course.requestAIAssessmentResponse.activation &&
            'hid' in assessment &&
            course.requestAIAssessmentResponse.assessment_hid === assessment.hid &&
            course.requestAIAssessmentResponse.is_general_tutor
        ) {
            let request_body: CourseState['AIRequestBody'] = {
                assessment_hid: course.requestAIAssessmentResponse.assessment_hid,
                assessment_type: course.requestAIAssessmentResponse.assessment_type,
                is_general_tutor: course.requestAIAssessmentResponse.is_general_tutor,
                accessToken: user.accessToken ? user.accessToken : undefined,
            }
            if (
                'accessToken' in user &&
                user.accessToken
            ) {
                dispatch(courseGetAIAssessmentRequestTicket(
                    {
                        body_data: request_body,
                        accessToken: user.accessToken,
                    }
                ))
            }
        }
    }, [
        dispatch,
        course.requestAIAssessmentResponse,
        assessment,
        course.generalTutorAssessment,
        user
    ]
    )
    useEffect(() => {
        let assessment = course.generalTutorAssessment
        if (
            assessment &&
            'hid' in assessment &&
            course.AIRequestTicket?.function_app_endpoint?.assessment_hid === assessment.hid
        ) {
            dispatch(runGeneralTutorAssessmentStream(
                {
                    requestTicket: course.AIRequestTicket,
                    request_body: course.AIRequestBody,
                }
            ))
        }
    }, [
        dispatch,
        course.AIRequestTicket,
        course.AIRequestBody,
        course.generalTutorAssessment
    ]
    )
    //
    if (!assessment) {
        return null
    }
    return (
        <div className={`block mb-48 mt-1`}>
            {course.loading.courseGetAIAssessmentRequestTicket ? (
                <TwLoading className='w-6 h-6 mt-4' />
            ) : (
                <>
                    {
                        assessment.assessment_type === 'FlashCards' ? (
                            <div className='text-sm max-w-prose prose prose-slate prose-sm dark:prose-invert m-auto'>
                                <TwFlashcardsUI is_general_tutor={true} />
                            </div>
                        ) : (null)
                    }
                    {
                        assessment.assessment_type === 'Quiz' ? (
                            <div className='text-sm max-w-prose prose prose-slate prose-sm dark:prose-invert m-auto'>
                                <TwQuizUI is_general_tutor={true} />
                            </div>
                        ) : (null)
                    }
                    {
                        assessment.assessment_type === 'Vocabulary' ? (
                            <div className='text-sm max-w-prose prose prose-slate prose-sm dark:prose-invert m-auto'>
                                <TwVocabularyUI is_general_tutor={true} />
                            </div>
                        ) : (null)
                    }
                    {
                        assessment.assessment_type === 'ClozeCards' ? (
                            <div className='text-sm max-w-prose prose prose-slate prose-sm dark:prose-invert m-auto'>
                                <TwClozeCardsUI is_general_tutor={true} />
                            </div>
                        ) : (null)
                    }
                </>
            )}
        </div>
    )
}
export function TwGeneralTutorRevisionAssessmentWrapper() {
    const course = useSelector((state: RootState) => state.course)
    //
    let assessment = null
    if (
        course.generalTutorAssessment
    ) {
        assessment = course.generalTutorAssessment
    }
    //
    if (!assessment) {
        return null
    }
    return (
        <div className={`block mb-48 mt-1`}>
            {course.loading.courseGetAIAssessmentRequestTicket ? (
                <TwLoading className='w-6 h-6 mt-4' />
            ) : (
                <>
                    {
                        assessment.assessment_type === 'FlashCards' ? (
                            <div className='text-sm max-w-prose prose prose-slate prose-sm dark:prose-invert m-auto'>
                                <TwFlashcardsUI is_general_tutor={true} is_revision={true} />
                            </div>
                        ) : (null)
                    }
                    {
                        assessment.assessment_type === 'Quiz' ? (
                            <div className='text-sm max-w-prose prose prose-slate prose-sm dark:prose-invert m-auto'>
                                <TwQuizUI is_general_tutor={true} is_revision={true} />
                            </div>
                        ) : (null)
                    }
                    {
                        assessment.assessment_type === 'Vocabulary' ? (
                            <div className='text-sm max-w-prose prose prose-slate prose-sm dark:prose-invert m-auto'>
                                <TwVocabularyUI is_general_tutor={true} is_revision={true} />
                            </div>
                        ) : (null)
                    }
                    {
                        assessment.assessment_type === 'ClozeCards' ? (
                            <div className='text-sm max-w-prose prose prose-slate prose-sm dark:prose-invert m-auto'>
                                <TwClozeCardsUI is_general_tutor={true} is_revision={true} />
                            </div>
                        ) : (null)
                    }
                </>
            )}
        </div>
    )
}
export function TwAssessmentDetail(props: { is_revision?: boolean }) {
    const course = useSelector((state: RootState) => state.course)
    //
    return (
        <>
            {(course?.courseAssessment && !props.is_revision) ? (
                <div id='chatWindow'>
                    <div className='w-full'>
                        <TwAssessmentWrapper
                            key={`assessment`}
                        >
                        </TwAssessmentWrapper>
                    </div>
                </div>
            ) : (
                course?.courseAssessment && props.is_revision ? (
                    <div id='chatWindow'>
                        <div className='w-full'>
                            <TwRevisionAssessmentWrapper
                                key={`revision_assessment`}
                            >
                            </TwRevisionAssessmentWrapper>
                        </div>
                    </div>
                ) : (
                    null
                )
            )}
        </>
    )
}
export function TwGeneralTutorAssessmentDetail(props: { is_revision?: boolean }) {
    const course = useSelector((state: RootState) => state.course)
    //
    return (
        <>
            {course?.generalTutorAssessment && !props.is_revision ? (
                <div id='chatWindow'>
                    <div className='w-full'>
                        <TwGeneralTutorAssessmentWrapper
                            key={`general_tutor_assessment`}
                        >
                        </TwGeneralTutorAssessmentWrapper>
                    </div>
                </div>
            ) : (
                course?.generalTutorAssessment && props.is_revision ? (
                    <div id='chatWindow'>
                        <div className='w-full'>
                            <TwGeneralTutorRevisionAssessmentWrapper
                                key={`general_tutor_revision_assessment`}
                            >
                            </TwGeneralTutorRevisionAssessmentWrapper>
                        </div>
                    </div>
                ) : (
                    null
                )
            )}
        </>
    )
}
export function TwAccordion() {
    // global states and disptach
    const user = useSelector((state: RootState) => state.user)
    const course = useSelector((state: RootState) => state.course)
    const dispatch = useDispatch<AppDispatch>();
    const [moduleTrackModal, setModuleTrackModal] = useState(false);
    const [moduleTrackTarget, setModuleTrackTarget] = useState(0);
    //
    let progressTrack: { [key: number]: number }[] = useMemo(() => {
        return []; // Initialize your array here
    }, []); // Empty dependency array means this runs only once
    let chapters: string[] = useMemo(() => {
        return []; // Initialize your array here
    }, []); // Empty dependency array means this runs only once

    let chapterTopics: { [key: string]: string[] } = useMemo(() => {
        return {}; // Initialize your array here
    }, []); // Empty dependency array means this runs only once

    const [chapterProgressState, setChapterProgressState] = useState<any>([])
    //
    let lessonNumber = course.lessonNumber ? course.lessonNumber : '1_1'
    let [activeChapter, setActiveChapter] = useState(
        String(Number(lessonNumber.split('_')[0]) - 1)
    )

    if ('courseProgress' in course) {
        if ('progress_track' in course.courseProgress) {
            progressTrack = course.courseProgress.progress_track
        }
    }
    if ('ordered_outline' in course.courseOutline) {
        if ('chapters' in course.courseOutline.ordered_outline) {
            chapters = course?.courseOutline?.ordered_outline?.chapters
        }
        if ('chapter_topics' in course.courseOutline.ordered_outline) {
            chapterTopics = course?.courseOutline?.ordered_outline?.chapter_topics
        }
    }


    const handleClick = (value: string) => {
        if (value === activeChapter) {
            setActiveChapter('');
        } else {
            setActiveChapter(String(value));
        }
    };

    useEffect(() => {
        setActiveChapter(String(Number(course?.lessonNumber?.split('_')[0]) - 1))
    }, [
        course.lessonNumber
    ])
    useEffect(() => {
        if (
            'name' in course.courseOutline &&
            'course_name' in course.courseProgress &&
            course.courseProgress.course_name === course.courseOutline.name
        ) {
            const allEqual_two = (arr: number[]) => arr.every(val => Number(val) === 2);
            let chapters_progress_state: { [key: string | number]: number | string } = {}
            if (progressTrack && chapters && chapterTopics) {
                for (const [chapter_idx, topics] of Object.entries(progressTrack)) {
                    chapters_progress_state[chapter_idx] = 1
                    if (
                        topics && chapterTopics &&
                        chapters && chapters.length > 0
                    ) {
                        if (
                            Object.keys(topics).length === Object.keys(chapterTopics[chapters[Number(chapter_idx)]]).length
                        ) {
                            if (allEqual_two(Object.values(topics))) {
                                chapters_progress_state[chapter_idx] = 2
                            }
                        }
                    }
                }
            }
            setChapterProgressState(chapters_progress_state)
        } else {
            setChapterProgressState([])
        }
    }, [
        setChapterProgressState,
        chapters,
        chapterTopics,
        progressTrack,
        course.courseProgress,
        course.courseOutline,
    ])
    const updateCourseProgress = (moduleID: number, chapterID: number | null, value: number, forAll: boolean = false) => {
        if (user.hid && course.courseId && user.accessToken && 'progress_track' in course.courseProgress) {
            let copy_progress = JSON.parse(JSON.stringify(course.courseProgress.progress_track));

            // Now update the outer object with the newly modified progress_track
            let copy_data = {}
            if (moduleID >= 0 && forAll && value >= 0) {
                if ('ordered_outline' in course.courseOutline) {
                    let module_name = course.courseOutline.ordered_outline.chapters[moduleID]
                    let newModuleProgress = course.courseOutline.ordered_outline.chapter_topics[module_name].reduce((acc: any, _, index) => {
                        acc[String(index)] = value;
                        return acc;
                    }, {});
                    copy_progress[moduleID] = newModuleProgress
                }
            } else if (moduleID >= 0 && typeof chapterID == 'number' && chapterID >= 0 && value) {
                if (!(moduleID in copy_progress)) {
                    copy_progress[moduleID] = []
                }
                copy_progress[moduleID][chapterID] = value;
            }
            copy_data = { ...course.courseProgress, progress_track: copy_progress };
            dispatch(courseSaveCourseSubscription({ cid: course.courseId, body_data: copy_data, accessToken: user.accessToken }));
        }
    }

    if (
        !chapters ||
        !chapterTopics
    ) {
        return null
    }
    return (
        <>
            {course.loading.courseGetOutline ? (
                <TwLoading />
            ) : (
                <>
                    <Modal show={moduleTrackModal} size="md" onClose={() => setModuleTrackModal(false)} popup>
                        <Modal.Header />
                        <Modal.Body>
                            <div className="text-center">
                                <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400 flex flex-col justify-center justify-items-center">
                                    <FontAwesomeIcon icon={faCircleExclamation} className="mx-auto mb-4 h-14 w-14 text-gray-400 dark:text-gray-200 m-auto mb-3" />
                                    This will apply the effect for the whole Chapter, are you sure ?
                                </h3>
                                <div className="flex justify-center gap-4">
                                    <TwButton
                                        onClick={(event) => {
                                            event.stopPropagation();
                                            setModuleTrackModal(false);
                                            if (user.hid && (!chapterProgressState[moduleTrackTarget] || chapterProgressState[moduleTrackTarget] === 1)) {
                                                if ('hid' in course.courseProgress) {
                                                    updateCourseProgress(
                                                        Number(moduleTrackTarget),
                                                        null,
                                                        2,
                                                        true
                                                    )
                                                }
                                            } else if (user.hid && chapterProgressState[moduleTrackTarget] === 2) {
                                                if ('hid' in course.courseProgress) {
                                                    updateCourseProgress(
                                                        Number(moduleTrackTarget),
                                                        null,
                                                        1,
                                                        true
                                                    )
                                                }
                                            }
                                        }}
                                    >
                                        {"Yes, I'm sure"}
                                    </TwButton>
                                    <TwButton onClick={() => setModuleTrackModal(false)}>
                                        No, cancel
                                    </TwButton>
                                </div>
                            </div>
                        </Modal.Body>
                    </Modal>
                    <div>
                        <Accordion allowZeroExpanded preExpanded={[Number(activeChapter)]} className='text-sm'>
                            {Object.keys(chapters).map((chapter, pidx) => (
                                <AccordionItem key={`accordion_item_${chapter}_${pidx}`} uuid={Number(pidx)} className='border-l border-white my-1'>
                                    <AccordionItemHeading>
                                        <AccordionItemButton className='bg-slate-900'>
                                            <div className="mb-0" id="headingTwo">
                                                <div
                                                    className={`${String(Number(course?.lessonNumber?.split('_')[0]) - 1) === String(chapter)
                                                        ? `!bg-gray-500 text-slate-50`
                                                        : `transition-none hover:!text-slate-400`
                                                        } group relative flex w-full items-center border-0 px-1 py-1 text-left transition [overflow-anchor:none] hover:z-[2] focus:z-[3] focus:outline-none overflow-scroll text-clip overflow-hidden rounded-r-md`}
                                                    onClick={() => handleClick(chapter)}
                                                    aria-expanded="true"
                                                    aria-controls="collapseOne"
                                                >
                                                    <div className='flex flex-row basis-10/12'>
                                                        {!user.hid ? (null) : (!chapterProgressState[pidx]
                                                            ? (
                                                                <FontAwesomeIcon icon={faCircle} className='mr-[5px]' />
                                                            ) : (chapterProgressState[pidx] === 1
                                                                ? (
                                                                    <FontAwesomeIcon icon={faSpinner} className='mr-[5px]' />
                                                                ) : (chapterProgressState[pidx] === 2
                                                                    ? (
                                                                        <FontAwesomeIcon icon={faCrown} className='mr-[5px] text-yellow-300' />
                                                                    ) : (null)
                                                                )))
                                                        }
                                                        {Number(chapter) + 1}  {chapters[Number(chapter)]}
                                                    </div>
                                                    <div className={`basis-2/12 flex justify-end align-right mr-2 ${Number(pidx) !== Number(activeChapter) || activeChapter === '' ? 'hidden' : null}`}>
                                                        {!user.hid ? (null) : (!chapterProgressState[pidx] || chapterProgressState[pidx] === 1
                                                            ? (
                                                                <FontAwesomeIcon
                                                                    icon={faSquareCheck}
                                                                    size="lg"
                                                                    className='hover:cursor-pointer text-sky-400 hover:text-sky-200'
                                                                    onClick={(event) => {
                                                                        event.stopPropagation();
                                                                        setModuleTrackModal(true);
                                                                        setModuleTrackTarget(pidx);
                                                                    }}
                                                                />
                                                            ) : (chapterProgressState[pidx] === 2
                                                                ? (
                                                                    <FontAwesomeIcon
                                                                        icon={faSquareXmark}
                                                                        size="lg"
                                                                        className='hover:cursor-pointer text-gray-400 hover:text-gray-200'
                                                                        onClick={(event) => {
                                                                            event.stopPropagation();
                                                                            setModuleTrackModal(true);
                                                                            setModuleTrackTarget(pidx);
                                                                        }}
                                                                    />
                                                                ) : (null)
                                                            ))
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel className='pl-3'>
                                        {Object.keys(chapterTopics[chapters[Number(chapter)]]).map((topics, cidx) => (
                                            <div className="py-1" key={`accordion_item_${chapter}_${pidx}_${cidx}`}>
                                                <div key={`lessonlink_${chapter}_${pidx}_${cidx}`}>
                                                    <Link
                                                        to={`/chat?cid=${course.courseId}&lnum=${Number(chapter) + 1}_${Number(topics) + 1}`}
                                                        onClick={() => {
                                                            dispatch(setActiveComponent('SubComponentChatInterface'));
                                                            if (user.isMdScreen) {
                                                                dispatch(setShowSideBar(false))
                                                            }
                                                        }
                                                        }
                                                        className={` 
                                                                        ml-1
                                                                        font-medium
                                                                        border-l
                                                                        ${String(Number(course?.lessonNumber?.split('_')[1]) - 1) === topics &&
                                                                String(Number(course?.lessonNumber?.split('_')[0]) - 1) === chapter
                                                                ? `!bg-gray-600 text-slate-50`
                                                                : `hover:!text-slate-400`
                                                            }
                                                                        overflow-scroll flex flex-row items-center p-1
                                                                        cursor-pointer
                                                                        rounded-r-md
                                                                    `}
                                                    >
                                                        <div className='flex flex-row basis-10/12'>
                                                            {!user.hid ? (null) : (!progressTrack
                                                                ? (
                                                                    <FontAwesomeIcon icon={faCircle} className='mr-[5px]' />
                                                                ) : (!progressTrack[Number(pidx)]
                                                                    ? (
                                                                        <FontAwesomeIcon icon={faCircle} className='mr-[5px]' />
                                                                    ) : (!progressTrack[Number(pidx)][Number(cidx)]
                                                                        ? (
                                                                            <FontAwesomeIcon icon={faCircle} className='mr-[5px]' />
                                                                        ) : (progressTrack[Number(pidx)][Number(cidx)] === 1
                                                                            ? (
                                                                                <FontAwesomeIcon icon={faSpinner} className='align-middle mr-[5px]' />
                                                                            ) : (progressTrack[Number(pidx)][Number(cidx)] === 2
                                                                                ? (
                                                                                    <FontAwesomeIcon icon={faCrown} className='mr-[5px] text-yellow-300' />
                                                                                ) : (null))
                                                                        ))))
                                                            }
                                                            {Number(topics) + 1} {`${chapterTopics[chapters[Number(chapter)]][Number(topics)]}`}
                                                        </div>
                                                        <div className='basis-2/12 flex justify-end align-right mr-2'>
                                                            {!user.hid ? (null) : (!progressTrack
                                                                ? (
                                                                    null
                                                                ) : (!progressTrack[Number(pidx)] ||
                                                                    progressTrack[Number(pidx)][Number(cidx)] === 1 ||
                                                                    !progressTrack[Number(pidx)][Number(cidx)]
                                                                    ? (
                                                                        <FontAwesomeIcon
                                                                            icon={faSquareCheck}
                                                                            size="lg"
                                                                            className='hover:cursor-pointer text-green-600 hover:text-green-200'
                                                                            onClick={(event) => {
                                                                                event.preventDefault();
                                                                                event.stopPropagation();
                                                                                if ('hid' in course.courseProgress) {
                                                                                    updateCourseProgress(
                                                                                        pidx,
                                                                                        cidx,
                                                                                        2
                                                                                    )
                                                                                }
                                                                            }}
                                                                        />
                                                                    ) : (progressTrack[Number(pidx)][Number(cidx)] === 2
                                                                        ? (
                                                                            <FontAwesomeIcon
                                                                                icon={faSquareXmark}
                                                                                size="lg"
                                                                                className='hover:cursor-pointer text-gray-400 hover:text-gray-200'
                                                                                onClick={(event) => {
                                                                                    event.preventDefault();
                                                                                    event.stopPropagation();
                                                                                    if ('hid' in course.courseProgress) {
                                                                                        updateCourseProgress(
                                                                                            pidx,
                                                                                            cidx,
                                                                                            1
                                                                                        )
                                                                                    }
                                                                                }}
                                                                            />
                                                                        ) : (null))
                                                                ))
                                                            }
                                                        </div>
                                                    </Link>
                                                </div>
                                            </div>
                                        ))}
                                    </AccordionItemPanel>
                                </AccordionItem>
                            ))}
                        </Accordion>
                    </div >
                </>
            )
            }
        </>
    );
}
export function TwGuestLoginModal(props: { guestModal?: boolean, setGuestModal?: any }) {
    const user = useSelector((state: RootState) => state.user)
    const dispatch = useDispatch<AppDispatch>();
    //
    const questModal = props.guestModal
    const setGuestModal = props.setGuestModal
    return (
        <>
            <Modal show={questModal} onClose={() => setGuestModal(false)}>
                <Modal.Header>You're Our Guest !</Modal.Header>
                <Modal.Body>
                    <button
                        type="submit"
                        className="border border-cyan-500 rounded-full w-3/4 text-center py-3 bg-gradient-to-r from-gray-50 to-white focus:outline-none my-1 flex items-center justify-center shadow-lg transform transition-transform duration-200 ease-in-out hover:scale-105 m-auto text-black hover:shadow-cyan-500/50"
                        onClick={() => {
                            dispatch(userCreateGuest())
                            setGuestModal(false)
                        }}
                    >
                        <TwSprkles
                            className='hover:cursor-pointer fill-cyan-500 !inline-block align-middle !w-6 !h-6 mr-2'
                        />
                        <span className="font-bold text-baseline">
                            {user.loading.userCreateGuest ? (
                                <TwLoading />
                            ) : (
                                'Continue as Guest'
                            )}
                        </span>
                        <TwSprkles
                            className='hover:cursor-pointer fill-cyan-500 !inline-block align-middle !w-6 !h-6 ml-2'
                        />
                    </button>
                    <button
                        type="submit"
                        className="border border-cyan-500 rounded-full w-3/4 text-center py-3 bg-gradient-to-r from-gray-50 to-white focus:outline-none my-1 flex items-center justify-center shadow-lg transform transition-transform duration-200 ease-in-out hover:scale-105 m-auto text-black hover:shadow-cyan-500/50"
                        onClick={() => {
                            dispatch(setActiveComponent('SubComponentUserRegistration'))
                            dispatch(setActiveSubComponent('PrivateComponentLogin'))
                        }}
                    >
                        <span className="font-bold text-baseline">Login/SignUp</span>
                    </button>
                    <style>{`.hover\\:shadow-cyan-500\\/50:hover {box-shadow: 0 4px 20px rgba(0, 255, 255, 0.5);}`}</style>
                </Modal.Body>
                <Modal.Footer>
                    <TwButton onClick={() => setGuestModal(false)}>Close</TwButton>
                </Modal.Footer>
            </Modal>
        </>
    )
}
